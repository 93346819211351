import React from 'react';
import ChecklistComponent from './SubComponents/ChecklistComponent';
import FAQ from '../FAQ';
import { easySplitFaqData } from '../../content/faqdata';

export const subscriptionsChecklist = [
  {
    id: 1,
    text: <>Paperless and same day onboarding</>,
    url: '/img/pricing/icons/icon2.svg',
    alt: 'icon2',
  },
  {
    id: 2,
    text: <>Easy to use dashboard</>,
    url: '/img/pricing/icons/icon4.svg',
    alt: 'icon4',
  },
  {
    id: 3,
    text: <>E-commerce plugins</>,
    url: '/img/pricing/icons/icon6.svg',
    alt: 'icon6',
  },
  {
    id: 4,
    text: <>Developer friendly integration kits</>,
    url: '/img/pricing/icons/icon8.svg',
    alt: 'icon8',
  },
];

const EasySplitContent = () => (
  <>
    <div className="flex flex-col lg:flex-row items-center justify-between mb-[48px] rounded-[4px] bg-cf-light-grey px-[16px] py-[24px] lg:p-[40px]">
      <div className="flex flex-wrap w-full xl:w-[585px] dash-line">
        <p className="font-semibold text-[16px] leading-[24px] mb-[12px] lg:mb-0">
          Automatically split commissions with vendors after every purchase
        </p>
      </div>
      <div className="flex flex-col text-left lg:text-end w-full lg:w-auto">
        <p className="hidden-mobile line-through font-semibold text-[14px] leading-[24px] text-cf-faded-black">
          0.25%
        </p>
        <h3 className="flex items-center lg:block font-body font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
          0.10%{' '}
          <span className="visible-mobile ml-[4px] line-through font-semibold text-[14px] leading-[24px] text-cf-faded-black">
            0.25%
          </span>
        </h3>
        <p className="font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
          per vendor split
        </p>
      </div>
    </div>
    <ChecklistComponent
      wrapperClass="mb-[28px] lg:mb-[48px] mt-[60px]"
      heading="Tailor-made for Indian businesses"
      data={subscriptionsChecklist}
    />
    <div className=" general-faqs pricing-faqs">
      <FAQ
        open="0"
        data={easySplitFaqData}
        supportLinkHref="https://www.cashfree.com/help/hc"
      />
    </div>
  </>
);

export default EasySplitContent;
