import React from 'react';
// import faqData from './faqdata';
import CashgramContent from '../components/PricingComponents/CashgramContent';
import AutoCollectContent from '../components/PricingComponents/AutoCollectContent';
import EasySplitContent from '../components/PricingComponents/EasySplitContent';
import InstantSettlementsContent from '../components/PricingComponents/InstantSettlementsContent';
import PaymentGatewayContent from '../components/PricingComponents/PaymentGatewayContent';
import PayoutsContent from '../components/PricingComponents/PayoutsContent';
// import ChecklistComponent from '../components/PricingComponents/SubComponents/ChecklistComponent';
import SubscriptionsContent from '../components/PricingComponents/SubscriptionsContent';
import VerificationSuiteContent from '../components/PricingComponents/VerificationSuiteContent';

export const lowestPricingList = [
  {
    id: 0,
    text: <>No setup or any other hidden fees</>,
  },
  {
    id: 1,
    text: <>Pay only for actual transactions</>,
  },
  {
    id: 2,
    text: <>Real-time transaction fees reporting</>,
  },
  {
    id: 3,
    text: <>Paperless and same day onboarding</>,
  },
];

export const subAccordionData = [
  {
    title: 'Payment Gateway',
    desc:
      'Accept payments in Indian Rupees (INR) and other 100+ foreign currencies with minimal integration effort and go live in no time.',
    icon: '/img/pricing/accordion/payment-gateway.svg',
    primaryButtonLink:
      'https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_PaymentGateway',
    secondaryButtonLink:
      'https://www.cashfree.com/contact-sales?source-action=Pricing%20Page&action=Contact%20Sales&button-id=ContactSales_PaymentGateway',
    content: <PaymentGatewayContent />,
  },
  {
    title: 'Subscriptions',
    desc:
      'Collect repeat payments easily with the most advanced recurring payments solution. Convert and retain more customers through higher success rates on mandate creation and auto-debit.',
    icon: '/img/pricing/accordion/subscription.svg',
    primaryButtonLink:
      'https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_Subscriptions',
    secondaryButtonLink:
      'https://www.cashfree.com/contact-sales?source-action=Pricing%20Page&action=Contact%20Sales&button-id=ContactSales_Subscriptions',
    content: <SubscriptionsContent />,
  },
  {
    title: 'Auto Collect',
    desc:
      'Accept payments directly in your bank account via UPI, NEFT, IMPS and RTGS. Match each payment to a customer or an order and reconcile automatically.',
    icon: '/img/pricing/accordion/auto-collect.svg',
    primaryButtonLink:
      'https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_AutoCollect',
    secondaryButtonLink:
      'https://www.cashfree.com/contact-sales?source-action=Pricing%20Page&action=Contact%20Sales&button-id=ContactSales_AutoCollect',
    content: <AutoCollectContent />,
  },
  {
    title: 'Easy Split',
    desc:
      'With Easy Split, collect payments from customers via any digital method, deduct commissions, and split payments to vendors, partners & your own bank accounts.',
    icon: '/img/pricing/accordion/easy-split.svg',
    primaryButtonLink:
      'https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_EasySplit',
    secondaryButtonLink:
      'https://www.cashfree.com/contact-sales?source-action=Pricing%20Page&action=Contact%20Sales&button-id=ContactSales_EasySplit',
    content: <EasySplitContent />,
  },
  {
    title: 'Instant Settlements',
    desc:
      'Receive your Payment Gateway settlements within seconds. Opt from a wide range of settlement options and never run out of funds.',
    icon: '/img/pricing/accordion/instant-settlements.svg',
    primaryButtonLink:
      'https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_InstantSettlements',
    secondaryButtonLink:
      'https://www.cashfree.com/contact-sales?source-action=Pricing%20Page&action=Contact%20Sales&button-id=ContactSales_InstantSettlements',
    content: <InstantSettlementsContent />,
  },
  {
    title: 'Payouts',
    desc:
      'Pay vendors, do instant customer refunds, disburse loans, etc instantly 24x7, even on a bank holiday.',
    icon: '/img/pricing/accordion/payouts.svg',
    primaryButtonLink:
      'https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_Payouts',
    secondaryButtonLink:
      'https://www.cashfree.com/contact-sales?source-action=Pricing%20Page&action=Contact%20Sales&button-id=ContactSales_Payouts',
    content: <PayoutsContent />,
  },
  {
    title: 'Cashgram',
    desc:
      'Payout links to send money without collecting receiver bank account details or UPI details.',
    icon: '/img/pricing/accordion/cashgram.svg',
    primaryButtonLink:
      'https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_Cashgram',
    secondaryButtonLink:
      'https://www.cashfree.com/contact-sales?source-action=Pricing%20Page&action=Contact%20Sales&button-id=ContactSales_Cashgram',
    content: <CashgramContent />,
  },
  {
    title: 'Verification Suite',
    desc: 'Complete individual and business identity verification suite.',
    icon: '/img/icons/verification-suite-icon.svg',
    primaryButton: 'Start Free Trial',
    secondaryButton: 'Request Price',
    primaryButtonLink:
      'https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_VerificationSuite',
    secondaryButtonLink:
      'https://www.cashfree.com/contact-sales?source-action=Pricing%20Page&action=Contact%20Sales&button-id=ContactSales_VerificationSuite',
    content: <VerificationSuiteContent />,
  },
];

export const accordionData = [
  {
    key: 0,
    heading: 'Collect Payments',
    content: (
      <>
        {subAccordionData.slice(0, 5).map((data) => {
          return <p key={data.title}>{data.title}</p>;
        })}
      </>
    ),
  },
  {
    key: 1,
    heading: 'Make Payouts',
    content: (
      <>
        {subAccordionData.slice(5, 7).map((data) => {
          return <p key={data.title}>{data.title}</p>;
        })}
      </>
    ),
  },
  {
    key: 2,
    heading: 'Verify Identity',
    content: (
      <>
        {subAccordionData.slice(7).map((data) => {
          return <p key={data.title}>{data.title}</p>;
        })}
      </>
    ),
  },
];

export const exploreData = [
  {
    id: 0,
    title: 'Instant Settlements',
    body:
      'Get access to your payment gateway collection within 15 minutes even on bank holidays. Say goodbye to cash flow problems.',
    url: 'https://www.cashfree.com/payment-links',
    iconname: '/img/pricing/settlements.svg',
  },
  {
    id: 1,
    title: 'Buy Now Pay Later',
    body:
      'Provide 30+ flexible payments on your checkout and increase your average order value by upto 40%.',
    url: 'https://www.cashfree.com/recurring-payment',
    iconname: '/img/pricing/buynow-paylater.svg',
  },
  {
    id: 2,
    title: 'Easy Split',
    body: 'Split vendor commissions and manage marketplace settlements.',
    url: 'https://www.cashfree.com/easy-split/split-payment-gateway',
    iconname: '/img/pricing/easy-split.svg',
  },
  {
    id: 3,
    title: 'Payouts',
    body:
      'Make payouts to any bank account / UPI ID / card/ AmazonPay / Paytm instantly even on a bank holiday.',
    url: 'https://www.cashfree.com/auto-e-collect',
    iconname: '/img/pricing/payouts.svg',
  },
];

export const customPricingList = [
  {
    id: 0,
    text: 'Early access to new features',
  },
  {
    id: 1,
    text: 'Dedicated account manager',
  },
  {
    id: 2,
    text: 'Discount pricing',
  },
  {
    id: 3,
    text: 'Support over WhatsApp in addition to other channels',
  },
];
