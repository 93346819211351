import React from 'react';
import PricingAccordion from './SubComponents/PricingAccordion';
import ChecklistComponent from './SubComponents/ChecklistComponent';
import FAQ from '../FAQ';
import { payoutsFaqData } from '../../content/faqdata';

export const existingBankAccount = [
  {
    id: 0,
    icon: '/img/pricing/logo/icici.png',
    name: 'ICICI Bank',
  },
  {
    id: 1,
    icon: '/img/pricing/logo/yes-bank.png',
    name: 'Yes Bank',
  },
  {
    id: 2,
    icon: '/img/pricing/logo/kotak.png',
    name: 'Kotak Bank',
  },
  {
    id: 3,
    icon: '/img/pricing/logo/indusind.png',
    name: 'IndusInd Bank',
  },
  {
    id: 4,
    icon: '/img/pricing/logo/axis.png',
    name: 'Axis Bank',
  },
  {
    id: 5,
    icon: '/img/pricing/logo/rbl.png',
    name: 'RBL Bank',
  },
];

const PayoutsContent = () => (
  <div>
    <PricingAccordion
      data={[
        {
          heading: 'Transfer Charges',
          title: 'Payouts',
          content: (
            <div className=" overflow-x-auto mb-8">
              <table className="table w-full">
                <thead>
                  <tr className="mt-4">
                    <th className="w-1/3 text-2sm font-semibold whitespace-nowrap">
                      Transaction Amount
                    </th>
                    <th className="w-1/5 text-2sm font-semibold whitespace-nowrap">
                      IMPS
                    </th>
                    <th className="w-1/5 text-2sm font-semibold whitespace-nowrap">
                      NEFT
                    </th>
                    <th className=" text-2sm font-semibold whitespace-nowrap">
                      UPI
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                      <span className="text-vtiny lg:text-[15px] font-medium">
                        up to{' '}
                      </span>
                      ₹1,000
                    </td>
                    <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                      ₹ 2.50
                    </td>
                    <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                      ₹ 1.75
                    </td>
                    <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                      ₹ 3.00
                    </td>
                  </tr>
                  <tr>
                    <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                      ₹1,000
                      <span className="text-vtiny lg:text-[15px] font-medium">
                        {' '}
                        to{' '}
                      </span>
                      ₹10,000
                    </td>
                    <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                      ₹ 5.00
                    </td>
                    <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                      ₹ 1.75
                    </td>
                    <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                      ₹ 6.00
                    </td>
                  </tr>
                  <tr>
                    <td className="pt-4 text-cf-primary font-semibold text-vtiny sm:text-md whitespace-nowrap">
                      <span className="text-vtiny lg:text-[15px] font-medium">
                        Above{' '}
                      </span>
                      ₹10,000
                    </td>
                    <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                      ₹ 10.00
                    </td>
                    <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                      ₹ 1.75
                    </td>
                    <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                      ₹ 10.00
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ),
        },
        {
          heading: 'Cards',
          subheading: (
            <>
              <span className="font-body mr-[16px] font-semibold text-[16px] leading-[24px] text-cf-primary">
                ₹10
              </span>
            </>
          ),
          content: (
            <div className="flex flex-col-reverse lg:flex-row items-center justify-between mb-[32px]">
              <div className="flex flex-wrap w-full xl:w-[550px] dash-line justify-center md:justify-start">
                <div className="md:flex md:flex-wrap  md:justify-start grid grid-cols-3 ">
                  <div className="text-center flex flex-col items-center space-y-[8px] w-[90px] first:mb-0 mt-[24px] lg:mt-0 lg:my-0 mr-[13px] lg:mr-[23px]">
                    <img
                      alt="visa"
                      height="48"
                      width="48"
                      src="/img/pricing/logo/visa.png"
                      loading="lazy"
                    />
                    <p className="text-[14px] leading-[19px] font-normal">
                      Visa
                    </p>
                  </div>
                  <div className="text-center flex flex-col items-center space-y-[8px] w-[90px] first:mb-0 mt-[24px] lg:mt-0 lg:my-0  mr-[13px] lg:mr-[23px]">
                    <img
                      alt="mastercard"
                      height="48"
                      width="48"
                      src="/img/pricing/logo/mastercard.png"
                      loading="lazy"
                    />
                    <p className="text-[14px] leading-[19px] font-normal">
                      Mastercard
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col text-left lg:text-end w-full lg:w-auto">
                <h3 className="font-body font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                  ₹10
                </h3>
                <p className="font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
                  per transaction
                </p>
              </div>
            </div>
          ),
        },
        {
          heading: 'Wallets',
          text:
            '* Limited period offer only. Applicable till 31st May 2023. T&C Apply',
          subheading: (
            <>
              <div className="inline-block mr-[8px] line-through font-medium text-[14px] leading-[24px] text-cf-faded-black">
                1%
              </div>
              <span className="font-body relative mr-[16px] font-semibold text-[16px] leading-[24px] text-cf-primary">
                Free
                <span className="text-md absolute top-[-3px] right-[-6px]">
                  *
                </span>
              </span>
            </>
          ),
          content: (
            <>
              <div className="flex flex-col-reverse lg:flex-row items-center justify-between mb-[32px]">
                <div className="flex flex-wrap w-full xl:w-[550px] dash-line justify-center md:justify-start">
                  <div className="md:flex md:flex-wrap  md:justify-start grid grid-cols-3 ">
                    <div className="text-center flex flex-col items-center space-y-[8px] w-[90px] first:mb-0 mt-[24px] lg:mt-0 lg:my-0  mr-[13px] lg:mr-[23px]">
                      <img
                        alt="Amazon Pay"
                        height={48}
                        width={48}
                        src="/img/pricing/amazonpay.svg"
                        loading="lazy"
                      />
                      <p className="text-[14px] leading-[19px] font-normal w-24 text-center">
                        Amazon Pay
                      </p>
                    </div>
                  </div>
                </div>

                <div className=" text-left lg:text-end w-full lg:w-auto">
                  <p className="hidden-mobile line-through font-semibold text-[14px] leading-[24px] text-cf-faded-black">
                    1%
                  </p>
                  <div className="flex items-center">
                    <h3 className="relative items-center inline-block  font-body font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                      Free{' '}
                      <span className="text-md absolute top-[-1px] right-[-6px]">
                        *
                      </span>
                    </h3>
                    <span className="visible-mobile ml-[8px] line-through font-semibold text-[14px] leading-[24px] text-cf-faded-black">
                      1%
                    </span>
                  </div>
                </div>
              </div>
              <hr className="my-[24px] lg:my-[32px]" />

              <div className="flex flex-col-reverse lg:flex-row items-center justify-between mb-[32px]">
                <div className="flex flex-wrap w-full xl:w-[550px] dash-line justify-center md:justify-start">
                  <div className="md:flex md:flex-wrap  md:justify-start grid grid-cols-3  ">
                    <div className="text-center flex flex-col items-center space-y-[8px] w-[90px] first:mb-0 mt-[24px] lg:mt-0 lg:my-0  mr-[13px] lg:mr-[23px]">
                      <img
                        alt="Paytm"
                        height={48}
                        width={48}
                        src="/img/pricing/paytm.svg"
                        loading="lazy"
                      />
                      <p className="text-[14px] leading-[19px] font-normal w-24 text-center">
                        Paytm
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-left lg:text-end w-full lg:w-auto">
                  <h3 className="font-body font-semibold text-[16px] leading-[24px] text-cf-primary">
                    As applicable on your <br /> Paytm business account
                  </h3>
                </div>
              </div>
            </>
          ),
        },
        {
          heading: 'Transfer Charges',
          title: 'Connected Payouts',
          text: 'Note: Above mentioned prices are exclusive of bank charges',
          content: (
            <>
              <div className="flex  lg:flex-row items-center justify-between ">
                <div className="flex flex-wrap w-full  justify-center md:justify-start">
                  <div className="md:flex md:flex-wrap  md:justify-start grid grid-cols-3 ">
                    {existingBankAccount.map((item) => (
                      <div
                        key={item.id}
                        className="text-center flex flex-col items-center space-y-[8px] w-[96px] first:mb-0 mt-[24px] lg:mt-0 lg:my-0 lg:first:mb-[32px] mx-[6px] lg:ml-0 lg:mr-[23px]">
                        <img
                          alt={item.name}
                          height={48}
                          width={48}
                          src={item.icon}
                          loading="lazy"
                        />
                        <p className="text-[14px] leading-[19px] font-normal">
                          {item.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <hr className="my-[24px] lg:mb-[32px] lg:mt-0" />
              <p className="font-semibold text-[14px] leading-[21px] text-cf-hero mb-[12px] lg:mb-[24px]">
                Transfer Charges
              </p>
              <div className=" overflow-x-auto mb-8">
                <table className="table w-full">
                  <thead>
                    <tr className="mt-4">
                      <th className="w-1/3 text-2sm font-semibold whitespace-nowrap">
                        Transaction Amount
                      </th>
                      <th className="w-1/5 text-2sm font-semibold whitespace-nowrap">
                        IMPS
                      </th>
                      <th className="w-1/5 text-2sm font-semibold whitespace-nowrap">
                        NEFT
                      </th>
                      <th className=" text-2sm font-semibold whitespace-nowrap">
                        UPI
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                        <span className="text-vtiny lg:text-[15px] font-medium">
                          up to{' '}
                        </span>
                        ₹1,000
                      </td>
                      <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                        ₹ 1.50
                      </td>
                      <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                        ₹ 2.00
                      </td>
                      <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                        ₹ 1.50
                      </td>
                    </tr>
                    <tr>
                      <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                        ₹1,001
                        <span className="text-vtiny lg:text-[15px] font-medium">
                          {' '}
                          to{' '}
                        </span>
                        ₹10,000
                      </td>
                      <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                        ₹ 2.00
                      </td>
                      <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                        ₹ 2.00
                      </td>
                      <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                        ₹ 2.00
                      </td>
                    </tr>
                    <tr>
                      <td className="pt-4 text-cf-primary font-semibold text-vtiny sm:text-md whitespace-nowrap">
                        <span className="text-vtiny lg:text-[15px] font-medium">
                          Above{' '}
                        </span>
                        ₹10,000
                      </td>
                      <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                        ₹ 2.50
                      </td>
                      <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                        ₹ 2.00
                      </td>
                      <td className="pt-4 text-cf-primary text-vtiny sm:text-md whitespace-nowrap">
                        ₹ 2.50
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          ),
        },
      ]}
    />

    <ChecklistComponent
      wrapperClass="mb-[28px] lg:mb-[48px] mt-[0px]"
      heading="Tailor-made for Indian businesses"
      data={[
        {
          id: 1,
          text: <>Paperless and same day onboarding</>,
          url: '/img/pricing/icons/icon2.svg',
          alt: 'icon2',
        },
        {
          id: 2,
          text: <>Easy to use dashboard</>,
          url: '/img/pricing/icons/icon4.svg',
          alt: 'icon4',
        },
        {
          id: 3,
          text: <>E-commerce plugins</>,
          url: '/img/pricing/icons/icon6.svg',
          alt: 'icon6',
        },
        {
          id: 4,
          text: <>Developer friendly integration kits</>,
          url: '/img/pricing/icons/icon8.svg',
          alt: 'icon8',
        },
      ]}
    />
    <div className=" general-faqs pricing-faqs">
      <FAQ
        open="0"
        data={payoutsFaqData}
        supportLinkHref="https://www.cashfree.com/help/hc"
      />
    </div>
  </div>
);

PayoutsContent.defaultProps = {};

PayoutsContent.propTypes = {};
export default PayoutsContent;
