/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import '../../Accordion/Accordion.scss';

function PricingAccordion({ data }) {
  const arrayActive = new Array(data.length).fill(false);
  arrayActive[0] = true;
  const [isTabActive, setTabActive] = useState(arrayActive);
  const [ing, setIng] = useState(1);
  // const [showText, setShowText] = useState(false);

  function onClick(index) {
    isTabActive[index] = !isTabActive[index];
    setIng(ing + 1);
    setTabActive(() => isTabActive);
    // setShowText(!showText);
  }

  return (
    <>
      <div className="accordion-container space-y-[16px]">
        {data.map((d, i) => (
          <div key={i}>
            {d.title && (
              <p className="font-body text-[20px] leading-[30px] md:text-4md font-semibold mb-[16px] md:mb-[24px] text-cf-hero">
                {d.title}
              </p>
            )}
            <div
              key={i}
              className="rounded-[4px] tab text-left overflow-hidden w-full font-semibold bg-cf-light-grey">
              <button
                className="text-left button-unstyled w-full flex flex-row items-center justify-between text-[16px] leading-[24px] accordion-heading font-semibold font-body text-cf-hero"
                type="button"
                onClick={() => onClick(i)}>
                {d.heading}
                {/* {console.log(isTabActive[i])} */}
                <div className="flex items-center">
                  {!isTabActive[i] ? d.subheading : ''}
                  <Icon
                    name="chevron-current"
                    width="11px"
                    className={`self-center flex-shrink-0 ${
                      isTabActive[i] ? 'text-cf-icon-light' : ''
                    }`}
                    rotate={isTabActive[i] ? '180deg' : '0deg'}
                  />
                </div>
              </button>
              <div
                className={`accordion-content !px-[16px] md:!px-[40px] ${
                  isTabActive[i] ? 'active !p-0' : ''
                }`}>
                {d.content}
              </div>
            </div>
            {d.text && (
              <p className="font-normal italic text-[12px] text-cf-faded-black mt-[12px] !mb-[48px]">
                {d.text}
              </p>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default PricingAccordion;
PricingAccordion.defaultProps = {
  data: [],
};
PricingAccordion.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ),
};
