import React from 'react';

const availAccessData = [
  {
    id: 0,
    url: '/img/pricing/dedicated-manager.svg',
    alt: 'Dedicated Account Manager',
    content: (
      <>
        <span className="text-white font-normal xl:font-semibold">
          Dedicated Account Manager
        </span>{' '}
        with fortnightly checkins to provide support and discuss business health
      </>
    ),
  },
  {
    id: 1,
    url: '/img/pricing/priority-business-support.svg',
    alt: 'Priority business support',
    content: (
      <>
        <span className="text-white font-normal xl:font-semibold">
          Priority business support
        </span>{' '}
        with an assured response time of 30 mins
      </>
    ),
  },
  {
    id: 2,
    url: '/img/pricing/faster-resolution.svg',
    alt: 'faster resolution',
    content: (
      <>
        <span className="text-white font-normal xl:font-semibold">
          20% faster resolution
        </span>{' '}
        to dispute management
      </>
    ),
  },
];

const AvailAccess = () => {
  return (
    <div className="bg-cf-dark relative py-[40px] xl:py-[48px] px-[16px] xl:px-[40px] rounded-[4px] mb-[48px] overflow-hidden avail-access">
      <div className="relative flex items-center justify-between flex-col xl:flex-row">
        <div className="max-w-full xl:max-w-[283px] mb-[20px] xl:mb-0 md:ml-[20px]">
          <h2 className="text-white font-body text-3md font-bold mb-[8px]">
            Avail exclusive access to our premium services
          </h2>
          <p className="text-cf-cold-purple text-[1rem] xl:text-[14px] leading-[24px]">
            with an annual maintenance charge
          </p>
        </div>
        <div className="max-w-full xl:max-w-[425px]">
          {availAccessData.map(({ id, url, alt, content }) => (
            <div
              className="flex items-start xl:items-center mb-[16px] xl:mb-[24px] last:mb-0"
              key={id}>
              <div className="w-[24px] xl:w-[32px] mr-[13px] xl:mr-[16px] flex-shrink-0 ">
                <img src={url} alt={alt} loading="lazy" />
              </div>
              <p className="text-cf-cold-purple text-[14px] leading-[21px] xl:leading-[24px] ">
                {content}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AvailAccess;
