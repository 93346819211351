import React from 'react';
import PricingAccordion from './SubComponents/PricingAccordion';
import ChecklistComponent from './SubComponents/ChecklistComponent';

export const subscriptionsChecklist = [
  {
    id: 1,
    text: <>Paperless and same day onboarding</>,
    url: '/img/pricing/icons/icon2.svg',
    alt: 'icon2',
  },
  {
    id: 2,
    text: <>Easy to use dashboard</>,
    url: '/img/pricing/icons/icon4.svg',
    alt: 'icon4',
  },
  {
    id: 3,
    text: <>E-commerce plugins</>,
    url: '/img/pricing/icons/icon6.svg',
    alt: 'icon6',
  },
  {
    id: 4,
    text: <>Developer friendly integration kits</>,
    url: '/img/pricing/icons/icon8.svg',
    alt: 'icon8',
  },
];

export const subscriptionCards = [
  {
    id: 0,
    icon: '/img/pricing/logo/visa.png',
    name: 'Visa',
  },
  {
    id: 1,
    icon: '/img/pricing/logo/mastercard.png',
    name: 'Mastercard',
  },
];

export const subscriptionEmandate = [
  {
    id: 0,
    icon: '/img/pricing/logo/nach.png',
    name: 'NACH',
  },
];

export const subscriptionUpiAutopay = [
  {
    id: 0,
    icon: '/img/pricing/logo/upi.png',
    name: 'UPI AutoPay',
  },
];

const SubscriptionsContent = () => (
  <>
    <div className="mb-[16px]">
      <PricingAccordion
        data={[
          {
            heading: 'Cards',
            subheading: (
              <>
                <span className="font-body mr-[16px] font-semibold text-[16px] leading-[24px] text-cf-primary">
                  2.25%
                </span>
              </>
            ),
            content: (
              <>
                <div className="flex flex-col-reverse lg:flex-row items-center justify-between mb-[32px]">
                  <div className="flex flex-wrap w-full xl:w-[500px] dash-line justify-center md:justify-start">
                    <div className="md:flex md:flex-wrap  md:justify-start grid grid-cols-3 ">
                      {subscriptionCards.map((item) => (
                        <div
                          key={item.id}
                          className="text-center flex flex-col items-center space-y-[8px] w-[90px] first:mb-0 mt-[24px] lg:mt-0 lg:my-0  mr-[13px] lg:mr-[23px]">
                          <img
                            alt={item.name}
                            height={48}
                            width={48}
                            src={item.icon}
                            loading="lazy"
                          />
                          <p className="text-[14px] leading-[19px] font-normal">
                            {item.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col text-left lg:text-end w-full lg:w-auto">
                    <h3 className="font-body font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                      2.25%
                    </h3>
                    <p className="font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
                      per transaction
                    </p>
                  </div>
                </div>
              </>
            ),
          },
          {
            heading: 'E-mandate',
            subheading: (
              <>
                <span className="font-body mr-[16px] font-semibold text-[16px] leading-[24px] text-cf-primary">
                  ₹7.5
                </span>
              </>
            ),
            content: (
              <>
                <div className="flex flex-col-reverse lg:flex-row items-center justify-between mb-[32px]">
                  <div className="flex flex-wrap w-full xl:w-[500px] dash-line justify-center md:justify-start">
                    <div className="md:flex md:flex-wrap  md:justify-start grid grid-cols-3  ">
                      {subscriptionEmandate.map((item) => (
                        <div
                          key={item.id}
                          className="text-center flex flex-col items-center space-y-[8px] w-[90px] first:mb-0 mt-[24px] lg:mt-0 lg:my-0  mr-[13px] lg:mr-[23px]">
                          <img
                            alt={item.name}
                            height={48}
                            width={48}
                            src={item.icon}
                            loading="lazy"
                          />
                          <p className="text-[14px] leading-[19px] font-normal">
                            {item.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex items-center w-full lg:w-auto">
                    <div className="mr-[12px] lg:mr-[10px] flex flex-col text-left lg:text-end w-auto lg:w-auto">
                      <h3 className="font-body font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                        ₹7.5
                      </h3>
                      <p className="w-max lg:w-auto font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
                        mandate creation
                      </p>
                    </div>
                    {/* <div className=" scale-[1.6] text-cf-primary">+</div> */}
                    <div className=" flex flex-col  lg:text-end w-auto lg:w-auto">
                      <h3 className="font-body font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                        <span className=" scale-[1.6] mr-[28px] lg:mr-[16px]">
                          +
                        </span>
                        ₹7.5
                      </h3>
                      <p className="text-end w-auto font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
                        per debit
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ),
          },
          {
            heading: 'UPI Autopay',
            subheading: (
              <>
                <span className="font-body mr-[16px] font-semibold text-[16px] leading-[24px] text-cf-primary">
                  ₹5
                </span>
              </>
            ),
            content: (
              <>
                <div className="flex flex-col-reverse lg:flex-row items-center justify-between mb-[32px]">
                  <div className="flex flex-wrap w-full xl:w-[500px] dash-line justify-center md:justify-start">
                    <div className="md:flex md:flex-wrap  md:justify-start grid grid-cols-3 ">
                      {subscriptionUpiAutopay.map((item) => (
                        <div
                          key={item.id}
                          className="text-center flex flex-col items-center space-y-[8px] w-[90px] first:mb-0 mt-[24px] lg:mt-0 lg:my-0  mr-[13px] lg:mr-[23px]">
                          <img
                            alt={item.name}
                            height={48}
                            width={48}
                            src={item.icon}
                            loading="lazy"
                          />
                          <p className="text-[14px] leading-[19px] font-normal">
                            {item.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* <div className="flex flex-col text-left lg:text-end w-full lg:w-auto">
                    <h3 className="font-body font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                      ₹5 + per debit
                    </h3>
                    <p className="font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
                      mandate creation
                    </p>
                  </div> */}
                  <div className="flex items-center w-full lg:w-auto">
                    <div className="mr-[12px] lg:mr-[10px] flex flex-col text-left lg:text-end w-auto lg:w-auto">
                      <h3 className="font-body font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                        ₹5
                      </h3>
                      <p className="w-max lg:w-auto font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
                        mandate creation
                      </p>
                    </div>
                    {/* <div className=" scale-[1.6] text-cf-primary">+</div> */}
                    <div className=" flex flex-col  lg:text-end w-auto lg:w-auto">
                      <h3 className="font-body font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                        <span className=" scale-[1.6] mr-[28px] lg:mr-[16px]">
                          +
                        </span>
                        ₹5
                      </h3>
                      <p className="text-end w-auto font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
                        per debit
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ),
          },
          {
            heading: 'Transfer Charges',
            subheading: '',
            content: (
              <div className="mb-[32px] overflow-x-auto">
                <table className="table w-full lg:w-1/2">
                  <thead>
                    <tr className="mt-4">
                      <th className="w-1/3 text-2sm font-semibold whitespace-nowrap">
                        Transaction Value
                      </th>
                      <th className="w-1/3 text-2sm font-semibold whitespace-nowrap">
                        Per Debit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="pt-4 font-semibold text-cf-primary text-vtiny lg:text-md whitespace-nowrap">
                        <span className="text-vtiny lg:text-[15px] font-medium opacity-80">
                          up to{' '}
                        </span>
                        ₹250
                      </td>
                      <td className="pt-4 text-cf-primary font-semibold text-vtiny lg:text-md whitespace-nowrap">
                        ₹2.50
                      </td>
                    </tr>
                    <tr>
                      <td className="pt-4 font-semibold text-cf-primary text-vtiny lg:text-md whitespace-nowrap">
                        ₹251
                        <span className="text-vtiny lg:text-[15px] font-medium opacity-80">
                          {' '}
                          to{' '}
                        </span>
                        ₹1,000
                      </td>
                      <td className="pt-4 text-cf-primary font-semibold text-vtiny lg:text-md whitespace-nowrap">
                        ₹5.00
                      </td>
                    </tr>
                    <tr>
                      <td className="pt-4 font-semibold text-cf-primary text-vtiny lg:text-md whitespace-nowrap">
                        <span className="text-vtiny lg:text-[15px] font-medium opacity-80">
                          Above{' '}
                        </span>
                        ₹1,000
                      </td>
                      <td className="pt-4 text-cf-primary font-semibold text-vtiny lg:text-md whitespace-nowrap">
                        ₹15.00
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
        ]}
      />
    </div>
    <div className="-mb-[48px]">
      <ChecklistComponent
        wrapperClass="mb-[16px] lg:mb-[36px] mt-[60px]"
        heading="Tailor-made for Indian businesses"
        data={subscriptionsChecklist}
      />
    </div>
  </>
);

SubscriptionsContent.defaultProps = {};

SubscriptionsContent.propTypes = {};
export default SubscriptionsContent;
