import React from 'react';

const ValueAddedServices = () => {
  return (
    <section className="my-[48px]">
      <div className="pt-[40px] pb-[20px] xl:py-[30px] px-[16px] xl:px-[48px] bg-cf-primary rounded relative overflow-hidden vas">
        <div className="flex justify-between items-center flex-col xl:flex-row relative">
          <div className="w-full xl:w-1/2 mb-[28px] xl:mb-0">
            <div className="text-white  text-[20px] leading-[30px] font-semibold  mb-[8px]">
              3 Products free with our PG!
            </div>
            <div className="max-w-full xl:max-w-[389px] text-[15px] xl:text-[14px] leading-[24px] text-cf-light-cold-purple">
              Use our{' '}
              <span className="font-bold">Payment Links, Payment Forms,</span>{' '}
              and <span className="font-bold">softPOS</span> products to collect
              payments wherever your customers are at no additional cost!
            </div>
          </div>
          <div className="max-w-[347px]">
            <img
              src="/img/pricing/vas-bg.png"
              alt="value added services"
              className="hidden-mobile"
              loading="lazy"
              width="825"
              height="504"
            />
            <img
              src="/img/pricing/vas-bg-mweb.png"
              alt="value added services"
              className="visible-mobile"
              loading="lazy"
              width="686"
              height="525"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ValueAddedServices;
