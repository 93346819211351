import React from 'react';
import PricingAccordion from './SubComponents/PricingAccordion';
import ChecklistComponent from './SubComponents/ChecklistComponent';
import FAQ from '../FAQ';
import { autoCollectFaqData } from '../../content/faqdata';

export const subscriptionsChecklist = [
  {
    id: 1,
    text: <>Paperless and same day onboarding</>,
    url: '/img/pricing/icons/icon2.svg',
    alt: 'icon2',
  },
  {
    id: 2,
    text: <>Easy to use dashboard</>,
    url: '/img/pricing/icons/icon4.svg',
    alt: 'icon4',
  },
  {
    id: 3,
    text: <>E-commerce plugins</>,
    url: '/img/pricing/icons/icon6.svg',
    alt: 'icon6',
  },
  {
    id: 4,
    text: <>Developer friendly integration kits</>,
    url: '/img/pricing/icons/icon8.svg',
    alt: 'icon8',
  },
];

const AutoCollectContent = () => (
  <>
    <div className="mb-[16px]">
      <PricingAccordion
        data={[
          {
            heading: 'Bank Transfer',
            subheading: (
              <>
                <div className="inline-block mr-[8px] font-medium text-[14px] leading-[24px] text-cf-faded-black">
                  Starting at
                </div>
                <span className="font-body mr-[16px] font-semibold text-[16px] leading-[24px] text-cf-primary">
                  ₹5
                </span>
              </>
            ),
            content: (
              <>
                <div className="flex flex-col lg:flex-row items-center justify-between mb-[22px]">
                  <div className="flex flex-wrap w-full xl:w-[585px] dash-line">
                    <p className="font-semibold text-[14px] leading-[21px] lg:ml-2 mb-3 lg:mb-0">
                      Virtual Account
                    </p>
                  </div>
                  <div className="flex flex-col text-left lg:text-end w-full lg:w-auto">
                    <h3 className="font-body font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                      ₹5
                    </h3>
                    <p className="font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
                      creation
                    </p>
                  </div>
                </div>
                <hr className="my-[24px] lg:my-[32px]" />
                <div className="flex flex-col lg:flex-row items-center justify-between mb-[32px]">
                  <div className="flex flex-wrap w-full xl:w-[585px] dash-line">
                    <p className="font-semibold text-[14px] leading-[21px] lg:ml-2 mb-3 lg:mb-0">
                      Reconcile IMPS/NEFT/RTGS payments
                    </p>
                  </div>
                  <div className="flex flex-col text-left lg:text-end w-full lg:w-auto">
                    <h3 className="font-body font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                      ₹10
                    </h3>
                    <p className="font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
                      per transaction
                    </p>
                  </div>
                </div>
              </>
            ),
          },
          {
            heading: 'UPI',
            subheading: (
              <>
                <div className="inline-block mr-[8px] font-medium text-[14px] leading-[24px] text-cf-faded-black">
                  Starting at
                </div>
                <span className="font-body relative mr-[16px] font-semibold text-[16px] leading-[24px] text-cf-primary">
                  0.30%
                  <span className="text-md absolute top-[-3px] right-[-8px]">
                    *
                  </span>
                </span>
              </>
            ),
            content: (
              <>
                <div className="flex flex-col lg:flex-row items-center justify-between mb-[22px]">
                  <div className="flex flex-wrap w-full xl:w-[585px] dash-line">
                    <p className="font-semibold text-[14px] leading-[21px] lg:ml-2 mb-3 lg:mb-0">
                      Virtual UPI IDs
                    </p>
                  </div>
                  <div className=" text-left lg:text-end w-full lg:w-auto">
                    <h3 className=" inline-block font-body relative font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                      ₹5
                      <span className="text-md absolute top-[-1px] right-[-8px]">
                        *
                      </span>
                    </h3>
                    <p className="font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
                      creation
                    </p>
                  </div>
                </div>
                <hr className="my-[24px] lg:my-[32px]" />
                <div className="flex flex-col lg:flex-row items-center justify-between mb-[32px]">
                  <div className="flex flex-wrap w-full xl:w-[585px] dash-line">
                    <p className="font-semibold text-[14px] leading-[21px] lg:ml-2 mb-3 lg:mb-0">
                      Reconcile UPI payments
                    </p>
                  </div>
                  <div className=" text-left lg:text-end w-full lg:w-auto">
                    <h3 className="inline-block font-body relative font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                      0.30%
                      <span className="text-md absolute top-[-1px] right-[-6px]">
                        *
                      </span>
                    </h3>
                    <p className="font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
                      per transaction
                    </p>
                  </div>
                </div>
              </>
            ),
          },
        ]}
      />
    </div>
    <p className="font-normal italic text-[12px] text-cf-faded-black mt-[12px]">
      * Please get in touch with us for tailored pricing.
    </p>
    <ChecklistComponent
      wrapperClass="mb-[28px] lg:mb-[48px] mt-[60px]"
      heading="Tailor-made for Indian businesses"
      data={subscriptionsChecklist}
    />
    <div className=" general-faqs pricing-faqs">
      <FAQ
        open="0"
        data={autoCollectFaqData}
        supportLinkHref="https://www.cashfree.com/help/hc"
      />
    </div>
  </>
);

AutoCollectContent.defaultProps = {};

AutoCollectContent.propTypes = {};
export default AutoCollectContent;
