import React from 'react';
// import PropTypes from 'prop-types';

import AvailAccess from './SubComponents/AvailAccess';
import ValueAddedServices from './SubComponents/ValueAddedServices';
import PricingAccordion from './SubComponents/PricingAccordion';
import ChecklistComponent from './SubComponents/ChecklistComponent';
import FAQ from '../FAQ';
import { faqData } from '../../content/faqdata';

export const paymentGatewayChecklist = [
  {
    id: 0,
    text: <>Next day settlement</>,
    url: '/img/pricing/icons/icon1.svg',
    alt: 'icon1',
  },
  {
    id: 1,
    text: <>Paperless and same day onboarding</>,
    url: '/img/pricing/icons/icon2.svg',
    alt: 'icon2',
  },
  {
    id: 2,
    text: <>Easy to use dashboard</>,
    url: '/img/pricing/icons/icon4.svg',
    alt: 'icon4',
  },
  {
    id: 3,
    text: <>International payments in 30+ currencies</>,
    url: '/img/pricing/icons/icon3.svg',
    alt: 'icon3',
  },
  {
    id: 4,
    text: <>Instant refunds</>,
    url: '/img/pricing/icons/icon5.svg',
    alt: 'icon5',
  },
  {
    id: 5,
    text: <>E-commerce plugins</>,
    url: '/img/pricing/icons/icon6.svg',
    alt: 'icon6',
  },
  {
    id: 6,
    text: <>Dedicated account manager</>,
    url: '/img/pricing/icons/icon7.svg',
    alt: 'icon7',
  },
  {
    id: 7,
    text: <>Developer friendly integration kits</>,
    url: '/img/pricing/icons/icon8.svg',
    alt: 'icon8',
  },
  {
    id: 8,
    text: <>Now live on Juspay. Connect today</>,
    url: '/img/pricing/icons/icon9.svg',
    alt: 'icon9',
  },
];

export const paymentModesInclude = [
  {
    id: 0,
    icon: '/img/pricing/logo/visa.png',
    name: 'Visa',
  },
  {
    id: 1,
    icon: '/img/pricing/logo/mastercard.png',
    name: 'Mastercard',
  },
  {
    id: 2,
    icon: '/img/pricing/logo/maestro.png',
    name: 'Maestro',
  },
  {
    id: 3,
    icon: '/img/pricing/logo/icici.png',
    name: 'ICICI',
  },
  {
    id: 4,
    icon: '/img/pricing/logo/amazonpay.png',
    name: 'Amazon Pay',
  },
  {
    id: 5,
    icon: '/img/pricing/logo/airtel-money.png',
    name: 'Airtel Money',
  },
  {
    id: 6,
    icon: '/img/pricing/logo/jio-money.png',
    name: 'Jio Money',
  },
  {
    id: 7,
    icon: '/img/pricing/logo/phonepe.png',
    name: 'PhonePe',
  },
  {
    id: 8,
    icon: '/img/pricing/logo/netbanking.png',
    name: 'Net Banking',
  },
];

export const paymentModesIncludeFold2 = [
  {
    id: 0,
    icon: '/img/pricing/logo/upi.png',
    name: 'UPI',
  },
  {
    id: 1,
    icon: '/img/pricing/logo/rupay.png',
    name: 'RuPay',
  },
];

export const creditCardEmi = [
  {
    id: 0,
    icon: '/img/pricing/logo/hdfc.png',
    name: 'HDFC',
  },
  {
    id: 1,
    icon: '/img/pricing/logo/kotak.png',
    name: 'Kotak',
  },
  {
    id: 2,
    icon: '/img/pricing/logo/icici.png',
    name: 'ICICI',
  },
  {
    id: 3,
    icon: '/img/pricing/logo/sbi.png',
    name: 'SBI',
  },
  {
    id: 4,
    icon: '/img/pricing/logo/baroda.png',
    name: 'Bank of Baroda',
  },
  {
    id: 5,
    icon: '/img/pricing/logo/standard.png',
    name: 'Standard Chartered',
  },
  {
    id: 6,
    icon: '/img/pricing/logo/axis.png',
    name: 'Axis',
  },
  {
    id: 7,
    icon: '/img/pricing/logo/yes-bank.png',
    name: 'Yes Bank',
  },
  {
    id: 8,
    icon: '/img/pricing/logo/federal.png',
    name: (
      <>
        Federal <br className="hidden-mobile" />
        Bank
      </>
    ),
  },
  {
    id: 9,
    icon: '/img/pricing/logo/other.png',
    name: 'Other Major Banks',
  },
];

export const debitCardEmi = [
  {
    id: 0,
    icon: '/img/pricing/logo/hdfc.png',
    name: (
      <>
        HDFC <br className="hidden-mobile" />
        Bank
      </>
    ),
  },
  {
    id: 1,
    icon: '/img/pricing/logo/kotak.png',
    name: (
      <>
        Kotak <br className="hidden-mobile" />
        Bank
      </>
    ),
  },
  {
    id: 2,
    icon: '/img/pricing/logo/axis.png',
    name: (
      <>
        Axis <br className="hidden-mobile" />
        Bank
      </>
    ),
  },
  {
    id: 3,
    icon: '/img/pricing/logo/other.png',
    name: 'Coming Soon',
  },
];

export const cardlessEmi = [
  {
    id: 0,
    icon: '/img/pricing/logo/hdfc.png',
    name: (
      <>
        HDFC <br className="hidden-mobile" />
        Bank
      </>
    ),
  },
  {
    id: 1,
    icon: '/img/pricing/logo/icici.png',
    name: (
      <>
        ICICI <br className="hidden-mobile" />
        Bank
      </>
    ),
  },
  {
    id: 2,
    icon: '/img/pricing/logo/kotak.png',
    name: (
      <>
        Kotak <br className="hidden-mobile" />
        Bank
      </>
    ),
  },
  {
    id: 3,
    icon: '/img/pricing/logo/idfc.png',
    name: (
      <>
        IDFC First <br className="hidden-mobile" />
        Bank
      </>
    ),
  },
  {
    id: 4,
    icon: '/img/pricing/logo/federal.png',
    name: (
      <>
        Federal <br className="hidden-mobile" />
        Bank
      </>
    ),
  },
  {
    id: 5,
    icon: '/img/pricing/logo/zest.png',
    name: 'Zest Money',
  },
  {
    id: 6,
    icon: '/img/pricing/logo/kreditbee.png',
    name: 'KreditBee',
  },
  {
    id: 7,
    icon: '/img/pricing/logo/cashe.png',
    name: 'CASHe',
  },
  {
    id: 8,
    icon: '/img/pricing/logo/home-credit.png',
    name: 'Home Credit',
  },
];

export const payLater = [
  {
    id: 0,
    icon: '/img/pricing/logo/lazypay.png',
    name: 'LazyPay',
  },
  {
    id: 1,
    icon: '/img/pricing/logo/ola-money.png',
    name: 'Ola Money Postpaid',
  },
  {
    id: 2,
    icon: '/img/pricing/logo/hdfc.png',
    name: 'HDFC via Flexmoney',
  },
  {
    id: 3,
    icon: '/img/pricing/logo/kotak.png',
    name: 'Kotak via Flexmoney',
  },
  {
    id: 4,
    icon: '/img/pricing/logo/simpl.png',
    name: 'Simpl',
  },
  {
    id: 5,
    icon: '/img/pricing/logo/freecharge.png',
    name: 'Freecharge Pay Later',
  },
  {
    id: 6,
    icon: '/img/pricing/logo/zest.png',
    name: 'Zest Money Pay Later',
  },
  {
    id: 7,
    icon: '/img/pricing/logo/ring.png',
    name: 'Ring',
  },
  {
    id: 8,
    icon: '/img/pricing/logo/mobikwik-zip.png',
    name: 'Mobikwik Zip',
  },
];

export const internationalCardsFold1 = [
  {
    id: 0,
    icon: '/img/pricing/logo/visa.png',
    name: 'Visa',
  },
  {
    id: 1,
    icon: '/img/pricing/logo/mastercard.png',
    name: 'Mastercard',
  },
];

export const internationalCardsFold2 = [
  {
    id: 0,
    icon: '/img/pricing/logo/paypal.png',
    name: 'Paypal',
  },
  {
    id: 1,
    icon: '/img/pricing/logo/paytm.png',
    name: 'Paytm',
  },
];

export const internationalCardsFold3 = [
  {
    id: 0,
    icon: '/img/pricing/logo/dinners-club.png',
    name: 'Diners Club',
  },
  {
    id: 1,
    icon: '/img/pricing/logo/amex.png',
    name: 'American Express',
  },
];

const PaymentGatewayContent = () => (
  <>
    <div className="mb-[16px]">
      <PricingAccordion
        data={[
          {
            heading: 'Payment Modes include',
            subheading: (
              <>
                <div className="inline-block mr-[8px] line-through font-medium text-[14px] leading-[24px] text-cf-faded-black">
                  2%
                </div>
                <span className="font-body mr-[16px] font-semibold text-[16px] leading-[24px] text-cf-primary">
                  1.90%
                </span>
              </>
            ),
            content: (
              <>
                <div className="flex flex-col-reverse lg:flex-row items-center justify-between mb-[22px]">
                  <div className="flex flex-wrap w-full xl:w-[585px] dash-line justify-center md:justify-start">
                    <div className=" md:flex md:flex-wrap  md:justify-start grid grid-cols-3">
                      {paymentModesInclude.map((item) => (
                        <div
                          key={item.id}
                          className="text-center flex flex-col items-center space-y-[8px] w-[90px] first:mb-0 mt-[24px] lg:mt-0 lg:my-0 lg:first:mb-[28px] mx-[6px] lg:ml-0 lg:mr-[23px]">
                          <img
                            alt={item.name}
                            height={48}
                            width={48}
                            src={item.icon}
                            loading="lazy"
                          />
                          <p className="text-[14px] leading-[19px] font-normal">
                            {item.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col text-left lg:text-end w-full lg:w-auto">
                    <p className="hidden-mobile line-through font-semibold text-[14px] leading-[24px] text-cf-faded-black">
                      2%
                    </p>
                    <h3 className="flex items-center lg:block font-body font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                      1.90%{' '}
                      <span className="visible-mobile ml-[4px] line-through font-semibold text-[14px] leading-[24px] text-cf-faded-black">
                        2%
                      </span>
                    </h3>
                    <p className="font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
                      per transaction
                    </p>
                  </div>
                </div>
                <hr className="mt-[4px] mb-[24px] lg:my-[32px]" />
                <div className="flex flex-col-reverse lg:flex-row items-center justify-between mb-[32px]">
                  <div className="flex flex-wrap w-full xl:w-[585px] dash-line justify-center md:justify-start">
                    <div className="md:flex md:flex-wrap  md:justify-start grid grid-cols-3">
                      {paymentModesIncludeFold2.map((item) => (
                        <div
                          key={item.id}
                          className="flex flex-col items-center space-y-[8px] w-[90px] first:mb-0 mt-[24px] lg:mt-0  mx-[6px] lg:ml-0 lg:mr-[23px]">
                          <img
                            alt={item.name}
                            height={48}
                            width={48}
                            src={item.icon}
                            loading="lazy"
                          />
                          <p className="text-[14px] leading-[19px] font-normal">
                            {item.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className=" text-left lg:text-end w-full lg:w-auto">
                    <h3 className="font-body inline-block relative font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                      0%
                      <span className="text-md absolute top-[-1px] right-[-6px]">
                        *
                      </span>
                    </h3>
                    <p className="font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
                      per transaction
                    </p>
                  </div>
                </div>
              </>
            ),
          },
          {
            heading: 'EMI',
            subheading: (
              <>
                <div className="inline-block mr-[8px] font-medium text-[14px] leading-[24px] text-cf-faded-black">
                  Starting at
                </div>
                <span className="font-body mr-[16px] font-semibold text-[16px] leading-[24px] text-cf-primary">
                  1.90%
                </span>
              </>
            ),
            content: (
              <>
                <p className="font-semibold text-[14px] leading-[21px] text-cf-hero mb-[12px] lg:mb-[24px]">
                  Credit Card EMI
                </p>
                <div className="flex flex-col-reverse lg:flex-row items-center justify-between mb-0 lg:mb-[22px]">
                  <div className="flex flex-wrap w-full xl:w-[585px] dash-line justify-center md:justify-start">
                    <div className="md:flex md:flex-wrap  md:justify-start grid grid-cols-3">
                      {creditCardEmi.map((item) => (
                        <div
                          key={item.id}
                          className="text-center flex flex-col items-center space-y-[8px] w-[90px] first:mb-0 mt-[24px] lg:mt-0 lg:my-0 lg:first:mb-[48px] mx-[6px] lg:ml-0 lg:mr-[23px]">
                          <img
                            alt={item.name}
                            height={48}
                            width={48}
                            src={item.icon}
                            loading="lazy"
                          />
                          <p className="text-[14px] leading-[19px] font-normal">
                            {item.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col text-left lg:text-end w-full lg:w-auto">
                    <h3 className="font-body font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                      TDR + .25%
                    </h3>
                    <p className="font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
                      per transaction
                    </p>
                  </div>
                </div>
                <hr className="my-[24px] lg:my-[32px]" />
                <p className="font-semibold text-[14px] leading-[21px] text-cf-hero mb-[12px] lg:mb-[24px]">
                  Debit Card EMI
                </p>
                <div className="flex flex-col-reverse lg:flex-row items-center justify-between mb-0 lg:mb-[22px]">
                  <div className="flex flex-wrap w-full xl:w-[585px] dash-line justify-center md:justify-start">
                    <div className="md:flex md:flex-wrap  md:justify-start grid grid-cols-3 ">
                      {debitCardEmi.map((item) => (
                        <div
                          key={item.id}
                          className="text-center flex flex-col items-center space-y-[8px] w-[90px] lg:w-[90px] first:mb-0 mt-[24px] lg:mt-0 lg:my-0  mx-[6px] lg:ml-0 lg:mr-[23px]">
                          <img
                            alt={item.name}
                            height={48}
                            width={48}
                            src={item.icon}
                            loading="lazy"
                          />
                          <p className="text-2.5sm font-normal">{item.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col text-left lg:text-end w-full lg:w-auto">
                    <h3 className="font-body font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                      1.50%
                    </h3>
                    <p className="font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
                      per transaction
                    </p>
                  </div>
                </div>
                <hr className="my-[24px] lg:my-[32px]" />
                <p className="font-semibold text-[14px] leading-[21px] text-cf-hero mb-[12px] lg:mb-[24px]">
                  Cardless EMI
                </p>
                <div className="flex flex-col-reverse lg:flex-row items-center justify-between mb-[24px] lg:mb-[32px]">
                  <div className="flex flex-wrap w-full xl:w-[585px] dash-line justify-center md:justify-start">
                    <div className="md:flex md:flex-wrap  md:justify-start grid grid-cols-3">
                      {cardlessEmi.map((item) => (
                        <div
                          key={item.id}
                          className="text-center flex flex-col items-center space-y-[8px] w-[90px] first:mb-0 mt-[24px] lg:mt-0 lg:my-0 lg:first:mb-[28px] mx-[6px] lg:ml-0 lg:mr-[23px]">
                          <img
                            alt={item.name}
                            height={48}
                            width={48}
                            src={item.icon}
                            loading="lazy"
                          />
                          <p className="text-2.5sm font-normal">{item.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col text-left lg:text-end w-full lg:w-auto">
                    <h3 className="font-body font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                      1.90%
                    </h3>
                    <p className="font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
                      per transaction
                    </p>
                  </div>
                </div>
              </>
            ),
          },
          {
            heading: 'Pay Later',
            subheading: (
              <>
                <div className="inline-block mr-[8px] line-through font-medium text-[14px] leading-[24px] text-cf-faded-black">
                  2.50%
                </div>
                <span className="font-body mr-[16px] font-semibold text-[16px] leading-[24px] text-cf-primary">
                  2.20%
                </span>
              </>
            ),
            content: (
              <>
                <div className="flex flex-col-reverse lg:flex-row items-center justify-between mb-[32px]">
                  <div className="flex flex-wrap w-full xl:w-[585px] dash-line justify-center md:justify-start">
                    <div className="md:flex md:flex-wrap  md:justify-start grid grid-cols-3">
                      {payLater.map((item) => (
                        <div
                          key={item.id}
                          className="text-center flex flex-col items-center space-y-[8px] w-[90px] first:mb-0 mt-[24px] lg:mt-0 lg:my-0 lg:first:mb-[48px] mx-[6px] lg:ml-0 lg:mr-[23px]">
                          <img
                            alt={item.name}
                            height={48}
                            width={48}
                            src={item.icon}
                            loading="lazy"
                          />
                          <p className="text-[14px] leading-[19px] font-normal">
                            {item.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col text-left lg:text-end w-full lg:w-auto">
                    <p className="hidden-mobile line-through font-semibold text-[14px] leading-[24px] text-cf-faded-black">
                      2.50%
                    </p>
                    <h3 className="flex items-center lg:block font-body font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                      2.20%{' '}
                      <span className="visible-mobile ml-[4px] line-through font-semibold text-[14px] leading-[24px] text-cf-faded-black">
                        2.50%
                      </span>
                    </h3>
                    <p className="font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
                      per transaction
                    </p>
                  </div>
                </div>
              </>
            ),
          },
          {
            heading: 'International Cards',
            subheading: (
              <>
                <div className="inline-block mr-[8px] font-medium text-[14px] leading-[24px] text-cf-faded-black">
                  Starting at
                </div>
                <span className="font-body mr-[16px] font-semibold text-[16px] leading-[24px] text-cf-primary">
                  2.95%
                </span>
              </>
            ),
            content: (
              <>
                <div className="flex flex-col-reverse lg:flex-row items-center justify-between mb-[22px]">
                  <div className="flex flex-wrap w-full xl:w-[585px] dash-line justify-center md:justify-start">
                    <div className="md:flex md:flex-wrap  md:justify-start grid grid-cols-3 ">
                      {internationalCardsFold1.map((item) => (
                        <div
                          key={item.id}
                          className="text-center flex flex-col items-center space-y-[8px] w-[90px] first:mb-0 mt-[24px] lg:mt-0 lg:my-0  mx-[6px] lg:mr-[23px]">
                          <img
                            alt={item.name}
                            height={48}
                            width={48}
                            src={item.icon}
                            loading="lazy"
                          />
                          <p className="text-[14px] leading-[19px] font-normal">
                            {item.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col text-left lg:text-end w-full lg:w-auto">
                    <h3 className="font-body font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                      3.50% + ₹7
                    </h3>
                    <p className="font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
                      per transaction
                    </p>
                  </div>
                </div>
                <hr className="my-[24px] lg:my-[32px]" />

                <div className="flex flex-col-reverse lg:flex-row items-center justify-between mb-[22px]">
                  <div className="flex flex-wrap w-full xl:w-[585px] dash-line justify-center md:justify-start">
                    <div className="md:flex md:flex-wrap  md:justify-start grid grid-cols-3">
                      {internationalCardsFold2.map((item) => (
                        <div
                          key={item.id}
                          className="text-center flex flex-col items-center space-y-[8px] w-[90px] first:mb-0 mt-[24px] lg:mt-0 lg:my-0  mx-[6px] lg:mr-[23px]">
                          <img
                            alt={item.name}
                            height={48}
                            width={48}
                            src={item.icon}
                            loading="lazy"
                          />
                          <p className="text-[14px] leading-[19px] font-normal">
                            {item.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="text-left lg:text-end w-full lg:w-auto">
                    <h3 className="font-body font-semibold text-[16px] leading-[24px] text-cf-primary">
                      Standard
                      <br />
                      payment gateway
                      <br />
                      charges applicable
                    </h3>
                  </div>
                </div>
                <hr className="my-[24px] lg:my-[32px]" />

                <div className="flex flex-col-reverse lg:flex-row items-center justify-between mb-[32px]">
                  <div className="flex flex-wrap w-full xl:w-[585px] dash-line justify-center md:justify-start">
                    <div className="md:flex md:flex-wrap  md:justify-start grid grid-cols-3 ">
                      {internationalCardsFold3.map((item) => (
                        <div
                          key={item.id}
                          className="text-center flex flex-col items-center space-y-[8px] w-[90px] first:mb-0 mt-[24px] lg:mt-0 lg:my-0  mx-[6px] lg:mr-[23px]">
                          <img
                            alt={item.name}
                            height={48}
                            width={48}
                            src={item.icon}
                            loading="lazy"
                          />
                          <p className="text-[14px] leading-[19px] font-normal">
                            {item.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex flex-col text-left lg:text-end w-full lg:w-auto">
                    <h3 className="font-body font-semibold text-[20px] lg:text-[24px] leading-[30px] lg:leading-[33px] text-cf-primary">
                      2.95%
                    </h3>
                    <p className="font-semibold lg:font-medium text-[12px] lg:text-[14px] leading-[14px] lg:leading-[18px] text-cf-faded-black">
                      per transaction
                    </p>
                  </div>
                </div>
              </>
            ),
          },
        ]}
      />
    </div>
    <p className="font-normal italic text-[12px] text-cf-faded-black mt-[12px]">
      * Platform fee shall be a fee paid by the Merchant for services such as
      Hosted Checkout, Plugins, SDKs, Dashboard, and Generation of Reports. The
      Platform Fee shall be calculated on either total transactions or a certain
      number of transactions or a certain percentage of transactions of the
      total transaction as may be mutually agreed between the parties. Platform
      fee is a fee other than the fee charged on the transaction for payment
      instruments.
    </p>
    <ValueAddedServices />
    <AvailAccess />
    <ChecklistComponent
      wrapperClass="mb-[28px] lg:mb-[48px] mt-[60px]"
      heading="Already using payment gateway?"
      data={paymentGatewayChecklist}
    />
    <div className=" general-faqs pricing-faqs">
      <FAQ
        open="0"
        data={faqData}
        supportLinkHref="https://www.cashfree.com/help/hc"
      />
    </div>
  </>
);

export default PaymentGatewayContent;
