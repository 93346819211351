/* eslint-disable prettier/prettier */
import React from 'react';
import Icon from '../Icon';
import FAQ from '../FAQ';
import { verificationSuiteFaqData } from '../../content/faqdata';

const verification = [
  {
    heading: 'Bank Account Verification',
    p1: 'Validate bank account number and account holder name',
    p2: 'Success rate of more than 97%',
    url: '/img/pricing/icons/bank.svg',
    ctaLink:
      'https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_BAV',
  },
  {
    heading: 'IFSC Verification',
    p1: 'Verify IFSC codes of your beneficiaries’ bank account',
    p2: 'Upto 10000 IFSC codes in one go',
    url: '/img/pricing/icons/ifsc.svg',
    ctaLink:
      'https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_IFSC',
  },
  {
    heading: 'UPI ID Verification',
    p1: 'Know if UPI ID or UPI VPA is valid',
    p2: 'Success rate of more than 97%',
    url: '/img/pricing/icons/upi_id.svg',
    ctaLink:
      'https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_UPIID',
  },
  {
    heading: 'PAN Verification',
    p1: 'Verify PAN details of your users at scale',
    p2: 'PAN Verification via OCR supported',
    url: '/img/pricing/icons/pan.svg',
    ctaLink:
      'https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_PAN',
  },
  {
    heading: 'Aadhaar Verification',
    p1: `Verify name, gender, address, state, UIDAI registered user’s image, father's name, date of birth, contact number, and email address`,
    p2: 'Aadhar Verification via OCR supported',
    url: '/img/pricing/icons/aadhaar.svg',
    ctaLink:
      'https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_Aadhaar',
  },
  {
    heading: 'GSTIN Verification',
    p1:
      'Verify is GSTIN is valid, and know tax payer type, legal name,date of GST registration, and GSTIN status',
    p2: 'DO single verification or upto 10000 instantly',
    url: '/img/pricing/icons/gstin.svg',
    ctaLink:
      'https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_GSTIN',
  },
  {
    heading: 'All in one onboarding SDK',
    p1:
      'With a single SDK - streamline user onboarding with no integration effort',
    p2: 'Ready-made UI screens to go live in no time',
    url: '/img/pricing/icons/all-in1.svg',
    ctaLink:
      'https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_AllInOneOnboardingSDK',
  },
];
function VerificationSuiteContent() {
  return (
    <div>
      {verification.map((data) => {
        return (
          <div
            key={data.heading}
            className="bg-cf-light-grey rounded-[4px] my-4 lg:px-8 lg:py-8 py-6 px-5">
            <div className="flex justify-between items-center flex-wrap">
              <div className="flex">
                <div className="min-w-max lg:mr-5 mr-3">
                  <img
                    src={data.url}
                    alt="cfimage"
                    className=""
                    loading="lazy"
                  />
                </div>
                <div className="max-w-[38rem]">
                  <div className="mb-3 font-semibold text-[16px]">
                    {data.heading}
                  </div>
                  <div className="flex  my-2 ">
                    <span className="w-5 h-5 flex justify-center items-center rounded-full mt-[2px] mr-2 bg-cf-primary flex-none">
                      <Icon
                        name="tick"
                        width="9.21px"
                        height="6.29px"
                        fill="#fff"
                      />
                    </span>
                    <p className="ml-2 font-normal text-sm md:max-w-[450px]">
                      {data.p1}
                    </p>
                  </div>
                  <div className="flex my-2 items-center">
                    <span className="w-5 h-5 flex justify-center items-center rounded-full mt-[2px] mr-2 bg-cf-primary flex-none">
                      <Icon
                        name="tick"
                        width="8.92px"
                        height="6.78px"
                        fill="#fff"
                      />
                    </span>
                    <p className="ml-2 font-normal text-sm">{data.p2}</p>
                  </div>
                </div>
              </div>
              <a
                href={data.ctaLink}
                className="mt-[24px] lg:mt-0 min-w-max button button-unstyled btn p-0 !text-cf-green group left-[83px] !right-auto">
                Start Free Trial
                <svg
                  className="!w-[6px] !h-[14px] ml-2.5 group-hover:translate-x-[3px] transition-transform duration-300"
                  width="6"
                  height="11"
                  viewBox="0 0 6 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 1L5 5.5L1 10"
                    stroke="#05C16E"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
            </div>
          </div>
        );
      })}
      <div className="general-faqs pricing-faqs mt-[60px]">
        <FAQ
          open="0"
          data={verificationSuiteFaqData}
          supportLinkHref="https://www.cashfree.com/help/hc"
        />
      </div>
    </div>
  );
}

VerificationSuiteContent.defaultProps = {};

VerificationSuiteContent.propTypes = {};
export default VerificationSuiteContent;
