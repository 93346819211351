/* eslint-disable no-shadow */
import React, { useState, useEffect, useRef } from 'react'; // Fragment ,
import Select from 'react-select';
import CheckList from '../components/CheckList/CheckList';
import GetStarted from '../components/GetStarted';
import Icon from '../components/Icon';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Accordion from '../components/Accordion/Accordion';
import PayoutsContent from '../components/PricingComponents/PayoutsContent';
import PaymentGatewayContent from '../components/PricingComponents/PaymentGatewayContent';
import SubscriptionsContent from '../components/PricingComponents/SubscriptionsContent';
import AutoCollectContent from '../components/PricingComponents/AutoCollectContent';
import EasySplitContent from '../components/PricingComponents/EasySplitContent';
import InstantSettlementsContent from '../components/PricingComponents/InstantSettlementsContent';

import {
  customPricingList,
  lowestPricingList,
  accordionData,
  subAccordionData,
} from '../content/pricing';
import '../styles/pricing-new.scss';
import PricingSubContent from '../components/PricingSubContent';
import CashgramContent from '../components/PricingComponents/CashgramContent';
import VerificationSuiteContent from '../components/PricingComponents/VerificationSuiteContent';

const PricingNew = (rest) => {
  const [subContent, setSubContent] = useState(0);
  const offsetVal = useRef(null);

  const handleClickScroll = () => {
    const element = document.getElementById('pricingbody');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      });
    }
    if (window.innerWidth < 768) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      }, 100);
    }
  };

  const generateAccordion = (subContent, setSubContent) => {
    accordionData[0].content = (
      <div>
        {subAccordionData.slice(0, 5).map((data, index) => {
          return (
            <button
              key={data.title}
              type="button"
              className={`mb-[12px] px-[10px] flex items-center w-[200px] hover:bg-[#6933d31f] rounded ${
                subContent === index ? 'bg-[#6933d31f] text-cf-primary' : ''
              }`}
              onClick={() => {
                setSubContent(index);
                handleClickScroll();
              }}>
              <img
                src={data.icon}
                alt={data.title}
                width={32}
                height={32}
                loading="lazy"
              />
              <p className="ml-[7px] my-2 text-2.5sm font-semibold">
                {data.title}
              </p>
            </button>
          );
        })}
      </div>
    );
    accordionData[1].content = (
      <div>
        {subAccordionData.slice(5, 7).map((data, index) => {
          return (
            <button
              key={data.title}
              type="button"
              className={`mb-2 px-[10px] flex items-center w-[200px] hover:bg-[#6933d31f] rounded ${
                subContent === index + 5 ? 'bg-[#6933d31f] text-cf-primary' : ''
              }`}
              onClick={() => {
                setSubContent(index + 5);
                handleClickScroll();
              }}>
              <img
                src={data.icon}
                alt={data.title}
                width={32}
                height={32}
                loading="lazy"
              />
              <p className="ml-[7px] my-2 text-2.5sm font-semibold">
                {data.title}
              </p>
            </button>
          );
        })}
      </div>
    );
    accordionData[2].content = (
      <div>
        {subAccordionData.slice(7).map((data, index) => {
          return (
            <button
              key={data.title}
              type="button"
              className={`mb-2 px-[10px] flex items-center w-[200px] hover:bg-[#6933d31f] rounded ${
                subContent === index + 7 ? 'bg-[#6933d31f] text-cf-primary' : ''
              }`}
              onClick={() => {
                setSubContent(index + 7);
                handleClickScroll();
              }}>
              <img
                src={data.icon}
                alt={data.title}
                width={32}
                height={32}
                loading="lazy"
              />
              <p className="ml-[7px] my-2 text-2.5sm font-semibold">
                {data.title}
              </p>
            </button>
          );
        })}
      </div>
    );
  };

  generateAccordion(subContent, setSubContent);

  const options = [
    {
      label: (
        <span className="text-2sm text-cf-primary capitalize font-semibold">
          Collect Payments
        </span>
      ),
      options: [
        {
          value: 'Payment Gateway',
          label: (
            <div className="flex items-center ml-[8px]">
              <img
                src="/img/icons/payment-gateway-2.svg"
                alt="Payment Gateway"
                width="18"
                height="18"
                loading="lazy"
              />
              <span className="text-[14px] leading-[24px] font-medium text-cf-hero capitalize ml-[8px]">
                Payment Gateway
              </span>
            </div>
          ),
          id: 0,
          desc:
            'Accept payments in Indian Rupees (INR) and other 100+ foreign currencies with minimal integration effort and go live in no time.',
          content: <PaymentGatewayContent />,
          icon: '/img/icons/payment-gateway-2.svg',
        },
        {
          value: 'Subscriptions',
          label: (
            <div className="flex items-center ml-[8px]">
              <img
                src="/img/icons/subscriptions.svg"
                alt="Subscriptions"
                width="18"
                height="18"
                loading="lazy"
              />
              <span className="text-[14px] leading-[24px] font-medium text-cf-hero capitalize ml-[8px]">
                Subscriptions
              </span>
            </div>
          ),
          id: 1,
          desc:
            'Collect repeat payments easily with the most advanced recurring payments solution. Convert and retain more customers through higher success rates on mandate creation and auto-debit.',
          content: <SubscriptionsContent />,
          icon: '/img/icons/subscriptions.svg',
        },
        {
          value: 'Auto Collect',
          label: (
            <div className="flex items-center ml-[8px]">
              <img
                src="/img/pricing/auto-collect-mweb.svg"
                alt="Auto Collect"
                width="17"
                height="18"
                loading="lazy"
              />
              <span className="text-[14px] leading-[24px] font-medium text-cf-hero capitalize ml-[8px]">
                Auto Collect
              </span>
            </div>
          ),
          id: 2,
          desc:
            'Accept payments directly in your bank account via UPI, NEFT, IMPS and RTGS. Match each payment to a customer or an order and reconcile automatically.',
          content: <AutoCollectContent />,
          icon: '/img/pricing/auto-collect-mweb.svg',
        },
        {
          value: 'Easy Split',
          label: (
            <div className="flex items-center ml-[8px]">
              <img
                src="/img/icons/easy-split.svg"
                alt="Easy Split"
                width="18"
                height="18"
                loading="lazy"
              />
              <span className="text-[14px] leading-[24px] font-medium text-cf-hero capitalize ml-[8px]">
                Easy Split
              </span>
            </div>
          ),
          id: 3,
          desc:
            'With Easy Split, collect payments from customers via any digital method, deduct commissions, and split payments to vendors, partners & your own bank accounts.',
          content: <EasySplitContent />,
          icon: '/img/icons/easy-split.svg',
        },
        {
          value: 'Instant Settlements',
          label: (
            <div className="flex items-center ml-[8px]">
              <img
                src="/img/icons/instant-settlements-new.svg"
                alt="Instant Settlements"
                width="18"
                height="18"
                loading="lazy"
              />
              <span className="text-[14px] leading-[24px] font-medium text-cf-hero capitalize ml-[8px]">
                Instant Settlements
              </span>
            </div>
          ),
          id: 4,
          desc:
            'Receive your Payment Gateway settlements within seconds. Opt from a wide range of settlement options and never run out of funds.',
          content: <InstantSettlementsContent />,
          icon: '/img/icons/instant-settlements-new.svg',
        },
      ],
    },
    {
      label: (
        <span className="text-2sm text-cf-primary capitalize font-semibold">
          Make Payouts
        </span>
      ),
      options: [
        {
          value: 'Payouts',
          label: (
            <div className="flex items-center ml-[8px]">
              <img
                src="/img/icons/payouts-green.svg"
                alt="Payouts"
                width="18"
                height="18"
                loading="lazy"
              />
              <span className="text-[14px] leading-[24px] font-medium text-cf-hero capitalize ml-[8px]">
                Payouts
              </span>
            </div>
          ),
          id: 5,
          desc:
            'Pay vendors, do instant customer refunds, disburse loans, etc instantly 24x7, even on a bank holiday.',
          content: <PayoutsContent />,
          icon: '/img/icons/payouts-green.svg',
        },
        {
          value: 'Cashgram',
          label: (
            <div className="flex items-center ml-[8px]">
              <img
                src="/img/icons/cashgram.svg"
                alt="Cashgram"
                width="18"
                height="18"
                loading="lazy"
              />
              <span className="text-[14px] leading-[24px] font-medium text-cf-hero capitalize ml-[8px]">
                Cashgram
              </span>
            </div>
          ),
          id: 6,
          desc:
            'Payout links to send money without collecting receiver bank account details or UPI details.',
          content: <CashgramContent />,
          icon: '/img/icons/cashgram.svg',
        },
      ],
    },
    {
      label: (
        <span className="text-2sm text-cf-primary capitalize font-semibold">
          Verify Identity
        </span>
      ),
      options: [
        {
          value: 'Verification Suite',
          label: (
            <div className="flex items-center ml-[8px]">
              <img
                src="/img/icons/auto-collect-3.svg"
                alt="Verification Suite"
                width="20"
                height="18"
                loading="lazy"
              />
              <span className="text-[14px] leading-[24px] font-medium text-cf-hero capitalize ml-[8px]">
                Verification Suite
              </span>
            </div>
          ),
          id: 7,
          desc: 'Complete individual and business identity verification suite.',
          content: <VerificationSuiteContent />,
          icon: '/img/icons/auto-collect-3.svg',
        },
      ],
    },
  ];

  const [selectedOption, setSelectedOption] = useState({
    value: 'Payment Gateway',
    label: (
      <div className="flex items-center ml-[8px]">
        <img
          src="/img/icons/payment-gateway-2.svg"
          alt="Payment Gateway"
          width="18"
          height="18"
          loading="lazy"
        />
        <span className="text-[14px] leading-[24px] font-medium text-cf-faded-black capitalize ml-[8px]">
          Payment Gateway
        </span>
      </div>
    ),
    id: 1,
    desc:
      'Accept payments in Indian Rupees (INR) and other 100+ foreign currencies with minimal integration effort and go live in no time.',
    content: <PaymentGatewayContent />,
    icon: '/img/icons/payment-gateway-2.svg',
  });

  const handleSelectedInput = (selectedOption) => {
    handleClickScroll();
    setSubContent(selectedOption.id);
    const option = selectedOption;
    option.label = (
      <div className="flex items-center ml-[8px]">
        <img
          src={selectedOption.icon}
          alt={selectedOption.value}
          width="18"
          height="18"
          loading="lazy"
        />
        <span className="text-[14px] leading-[24px] font-medium text-cf-faded-black capitalize ml-[8px]">
          {selectedOption.value}
        </span>
      </div>
    );
    setSelectedOption(option);
  };

  useEffect(() => {
    const pricingEnterprises = document.querySelector('.pricing-enterprises');
    const pricingStartsAt = document.querySelector('.pricing-starts-at');

    const scaleDown = () => {
      pricingStartsAt.classList.add('scaledown');
    };
    const scaleUp = () => {
      pricingStartsAt.classList.remove('scaledown');
    };

    pricingEnterprises.addEventListener('mouseenter', scaleDown);
    pricingEnterprises.addEventListener('mouseleave', scaleUp);

    return () => {
      window.removeEventListener('mouseenter', scaleDown);
      window.removeEventListener('mouseleave', scaleUp);
    };
  }, []);

  return (
    <Layout
      lightHeader
      {...rest}
      signInLink="https://merchant.cashfree.com/merchant/login?source-action=Pricing%20Page&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="Payment Gateway Charges | Lowest Pricing & UPI Payment Gateway Fees in India | Cashfree"
        description="Payment Gateway Charges- Best gateway charges with zero setup & maintenance cost, TDR @ 1.90%. Use robust APIs to collect domestic & international payments via 120+ payment modes."
        url="https://www.cashfree.com/payment-gateway-charges/"
        keywords={[
          'payment gateway charges',
          'payment gateway charges india',
          'payment gateway pricing',
          'paypal payment gateway charges',
          'payu payment gateway charges',
          'ccavenue payment gateway charges',
          'paypal payment gateway charges',
          'cashfree charges',
          'cash free charges',
          'cashfree payment gateway charges',
          'cashfree payment gateway pricing',
          'billdesk payment gateway charges',
          'hdfc payment gateway charges',
          'gateway charges',
          'UPI payment gateway charges',
          'payment gateway fees',
          'lowest transaction fee payment gateway',
          'lowest payment gateway charges',
          'payment gateway integration charges',
          'online payment gateway charges',
        ]}
      />
      <section className="pb-0 md:pb-[145px] pt-[110px] md:pt-[175px] bg-cf-dark text-white relative pricing-hero overflow-hidden ">
        <div className=" container position-relative z-10">
          <div className="flex flex-wrap flex-col md:flex-row md:flex-nowrap justify-start md:justify-between lg:w-[100%]">
            <div className="w-full md:w-1/2 pb-0 lg:self-center">
              <h2 className="text-sm font-semibold text-white pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                Pricing
              </h2>
              <div className="mb-8 md:mb-5 text-xl md:text-[40px] md:leading-[1.2] font-semibold max-w-[600px] font-heading">
                Lowest <br />
                <h1 className="inline">
                  payment gateway <br />
                  charges
                </h1>{' '}
                in India
              </div>
            </div>
            <div className=" md:w-4/6 md:flex md:items-center">
              <div className="bg-white px-4 md:px-6 py-6 sm:h-full rounded-l-[4px] flex flex-col justify-center relative  overflow-hidden  border-cf-primary w-full md:w-2/4 duration-300 transition-all pricing-starts-at ">
                <div className="text-cf-dark firstdiv">
                  <p className="text-2sm md:text-[14px] leading-[24px] text-cf-faded-black font-medium  mb-[8px] md:mb-[4px]">
                    Starts at
                  </p>
                  <p className="text-cf-primary relative inline-block text-4md md:text-[40px] md:leading-[48px] font-bold md:mb-[14px] pricing-percent">
                    1.90%
                    <span className="text-md absolute top-0 -right-[5px]">
                      *
                    </span>
                  </p>
                  <span className="ml-[10px] line-through text-md font-semibold text-cf-faded-black">
                    2.0%
                  </span>
                  <h3 className="font-body font-semibold text-cf-hero text-2.5sm mt-0 mb-[20px] pricing-subtext ">
                    Access complete payments and banking platform at friendly
                    pricing
                  </h3>
                  <CheckList
                    data={lowestPricingList}
                    className="mb-0"
                    itemMargin="mb-[12px]"
                    fontClass="text-2.5sm md:text-[14px]  md:leading-6 text-cf-hero"
                  />
                  <a
                    className="button button-green w-full text-2sm leading-[20px] border-none font-semibold mt-[32px] md:w-auto mr-0 flex items-center justify-center pricing-btn-green !p-[10px]"
                    href="https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_Hero">
                    Create Account
                    <span className="chevron" />
                  </a>
                </div>
                <small className="font-normal italic text-[12px] leading-[20px] text-cf-cold-purple mt-[12px]">
                  *
                  <h2 className="inline font-body">
                    Pricing for Payment Gateway
                  </h2>{' '}
                  only. See for
                  <br /> other{' '}
                  <button
                    onClick={handleClickScroll}
                    type="button"
                    className="font-semibold text-cf-green cursor-pointer italic border-none outline-none">
                    products
                  </button>
                </small>
              </div>
              <div className="bg-[#3B1F66] mt-12 mb-9 md:my-0 w-full md:w-2/4 px-[16px] md:px-[24px] py-[24px] sm:h-full rounded-r-[4px] flex flex-col relative overflow-hidden border-t-[7px] border-cf-primary duration-300 transition-all pricing-enterprises">
                <div className="text-cf-white">
                  <p className="text-[12px] md:text-[13px] leading-[20px] text-cf-cold-purple font-semibold uppercase mb-[8px] md:mb-[10px]">
                    Enterprises
                  </p>
                  <h3 className="font-body font-bold md:font-semibold text-[20px] leading-8">
                    Custom pricing
                    <br /> designed for enterprises
                  </h3>
                  <div className="checklist-wrapper my-[32px] md:my-[12px]  ">
                    {customPricingList.map(({ id, text }) => (
                      <div key={id} className="flex mb-[12px]">
                        <span className="mt-0.5 w-5 h-5 flex justify-center items-center rounded-full mr-[10px] bg-[#ffffff40]">
                          <Icon
                            name="tick"
                            width="9.21px"
                            height="6.29px"
                            fill="#fff"
                            className="w-2 h-2"
                          />
                        </span>
                        <p className="flex-1 m-0 text-[14px] md:text-[15px] leading-[21px] md:leading-[24px] ">
                          {text}
                        </p>
                      </div>
                    ))}
                  </div>
                  <p className="text-tiny text-cf-cold-purple mt-[26px]">
                    Get in touch with our sales team to explore the right
                    products for your payment needs and get custom&nbsp;pricing.
                  </p>
                  <a
                    className="button button-outline w-full !shadow-none mt-[20px] md:mt-[12px] md:w-auto mb-4 md:mb-0 mr-0 flex items-center justify-center"
                    href="https://www.cashfree.com/contact-sales?source-action=Pricing%20Page&action=Contact%20Sales&button-id=ContactSales_Hero">
                    Contact Sales
                    <span className="chevron" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="pricingbody" className="pricingbody" ref={offsetVal}>
        <div className=" md:px-[20px] lg:px-[91px] bg-cf-light-grey ">
          <div className="container md:flex">
            <div className="pt-[120px] border-r border-cf-stroke md:block hidden">
              <div className="left-side-accordion">
                <Accordion
                  data={accordionData}
                  className="root md:w-[260px] lg:[300px]"
                  buttonClassName="!px-0 !py-5 !text-[16px] !leading-6"
                  contentClassName="!pl-0"
                  border
                  colored
                  defaultOpen
                  // closed={subContent === 7}
                  arrow="chevron-current"
                  arrowClassName="w-[72px] px-6"
                />
              </div>
            </div>
            <div className="bg-white sticky top-0 left-0 z-50 block px-4 md:hidden mt-[48px]">
              <div className="custom-select-section">
                <Select
                  options={options}
                  value={selectedOption}
                  onChange={handleSelectedInput}
                  isSearchable={false}
                  onKeyDown={(e) => {
                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                      e.preventDefault();
                    }
                  }}
                  classNames={{
                    option: (state) =>
                      `${
                        state.isSelected
                          ? 'activeOption'
                          : state.isFocused && '!bg-white'
                      }`,
                  }}
                  styles={{
                    dropdownIndicator: (base, state) => ({
                      ...base,
                      transition: 'all .2s ease',
                      transform: state.selectProps.menuIsOpen
                        ? 'rotate(180deg)'
                        : null,
                    }),
                    option: (base, state) => {
                      return {
                        ...base,
                        // eslint-disable-next-line no-nested-ternary
                        backgroundColor:
                          state.isSelected || state.isFocused
                            ? 'rgba(105, 51, 211, 0.1)'
                            : base.backgroundColor,
                        borderBottomStyle: 'solid',
                        paddingLeft: 8,
                        ':active': {
                          color: 'rgba(#6933D3, 0.8)',
                        },
                        color: state.isSelected ? '#6933D3' : base.color,
                        fontWeight: state.isSelected ? 500 : 400,
                      };
                    },
                  }}
                />
              </div>
            </div>
            <div className="hidden md:block mt-[48px] md:mt-24 ml-0 md:ml-[20px] lg:ml-10 mb-0 md:mb-[96px] w-full bg-white px-[16px] md:px-[20px] lg:px-[48px] py-[48px] md:py-[40px] relative overflow-hidden bg-pattern ">
              <PricingSubContent
                title={subAccordionData[subContent].title}
                icon={subAccordionData[subContent].icon}
                desc={subAccordionData[subContent].desc}
                content={subAccordionData[subContent].content}
                primaryButton={subAccordionData[subContent].primaryButton}
                secondaryButton={subAccordionData[subContent].secondaryButton}
                primaryButtonLink={
                  subAccordionData[subContent].primaryButtonLink
                }
                secondaryButtonLink={
                  subAccordionData[subContent].secondaryButtonLink
                }
              />
            </div>
            <div className="block md:hidden  md:mt-24 ml-0 md:ml-10 mb-0 md:mb-[96px] w-full bg-white px-[16px] md:px-[48px] py-[48px] md:py-[40px] ">
              <PricingSubContent
                title={selectedOption && selectedOption.value}
                icon={selectedOption && selectedOption.icon}
                desc={selectedOption && selectedOption.desc}
                content={selectedOption && selectedOption.content}
                primaryButton={subAccordionData[subContent].primaryButton}
                secondaryButton={subAccordionData[subContent].secondaryButton}
                primaryButtonLink={
                  subAccordionData[subContent].primaryButtonLink
                }
                secondaryButtonLink={
                  subAccordionData[subContent].secondaryButtonLink
                }
              />
            </div>
          </div>
        </div>
      </section>

      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=Pricing%20Page&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
};

export default PricingNew;
