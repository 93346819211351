import React from 'react';
import PropTypes from 'prop-types';

function PricingSubContent({
  title,
  icon,
  desc,
  primaryButton,
  primaryButtonLink,
  secondaryButton,
  secondaryButtonLink,
  content,
}) {
  return (
    <div className=" ">
      <div>
        <div className="flex items-center mb-[16px]">
          <img
            src={icon}
            alt={title}
            className="w-[32px] h-[32px] md:w-[48px] md:h-[48px]"
            loading="lazy"
          />
          <h2 className="ml-[14px] text-4md md:text-shl">{title}</h2>
        </div>
        <p className="text-[15px] leading-[21px] md:leading-[24px] mb-[32px] md:mb-[24px] max-w-[715px]">
          {desc}
        </p>
        <div className="mb-[48px] md:flex">
          <a
            className="button button-green w-full mb-[15px] md:w-auto md:mb-0 md:mr-4"
            href={primaryButtonLink}>
            {primaryButton}
            <span className="chevron" />
          </a>
          <a
            className="button button-light w-full mb-3 md:w-auto md:mb-0 text-cf-hero border-cf-stroke"
            href={secondaryButtonLink}>
            {secondaryButton}
            <span className="chevron" />
          </a>
        </div>
      </div>
      {content}
    </div>
  );
}

PricingSubContent.defaultProps = {
  title: '',
  icon: '',
  desc: '',
  primaryButton: 'Create Account',
  primaryButtonLink: '',
  secondaryButton: 'Contact Sales',
  secondaryButtonLink: '',
  content: '',
};
PricingSubContent.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  desc: PropTypes.string,
  primaryButton: PropTypes.string,
  primaryButtonLink: PropTypes.string,
  secondaryButton: PropTypes.string,
  secondaryButtonLink: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
export default PricingSubContent;
