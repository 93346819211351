import React from 'react';

export const faqData = [
  {
    id: 0,
    q:
      'Are the payment gateway charges fixed? Can I get tailored pricing for my business?',
    a: (
      <>
        <div className="text-[14px] leading-[1.5] mt-[12px] max-w-[773px]">
          Yes. We can offer{' '}
          <h2 className="font-body inline">
            customized payment gateway charges
          </h2>{' '}
          if your business has very high average order values or
          micro-transactions. For custom, pricing get in touch with us{' '}
          <a
            href="https://www.cashfree.com/contact-sales?source-action=Pricing%20Page&action=Contact%20Sales&button-id=ContactSales_PGFAQ1"
            className="email-link">
            here
          </a>{' '}
          .
        </div>
      </>
    ),
  },
  {
    id: 1,
    q: 'What is TDR?',
    a: (
      <>
        <p>
          TDR or{' '}
          <a
            href="https://www.cashfree.com/resources/merchant-discount-rate"
            className="email-link">
            Transaction Discount Rate
          </a>{' '}
          is the per transaction cost a payment gateway charges for usage.
          Cashfree charges among the lowest TDR in the form of payment gateway
          charges.
        </p>
      </>
    ),
  },
  {
    id: 2,
    q: 'When can I start accepting international cards?',
    a: (
      <>
        <p>
          Once you upload all payment gateway integration{' '}
          <a href="https://www.cashfree.com/help/hc" className="email-link">
            related documents,
          </a>{' '}
          we verify the details. As your account gets approved, our team will
          assist you with international card payment activation.
        </p>
      </>
    ),
  },
  {
    id: 3,
    q:
      'I have a PayPal business account? What are the Cashfree payment gateway charges for enabling PayPal on the checkout page?',
    a: (
      <p>
        If you already have a PayPal business account, you can connect your
        PayPal account with Cashfree payment gateway account and start accepting
        international payments from day one. Read our{' '}
        <a
          href="https://docs.cashfree.com/docs/payment-gateway-dashboard#orders"
          className="email-link">
          Cashfree PayPal payment gateway integration guide
        </a>{' '}
        . PayPal integration in Cashfree Payment Gateway is FREE. If you decide
        to enable{' '}
        <a
          href="https://docs.cashfree.com/docs/payment-gateway-dashboard#orders"
          className="email-link">
          PayPal Express Checkout
        </a>{' '}
        on your Cashfree checkout page, you will be bearing only standard PayPal
        pricing, Cashfree does not charge any additional payment gateway
        charges.
      </p>
    ),
  },
  {
    id: 4,
    q: 'Are the payment gateway charges deducted on a per transaction basis?',
    a: (
      <>
        <p>
          Yes, the TDR or transaction discount rate is deducted on a per
          transaction basis. When a transaction is successful, the TDR amount is
          deducted by Cashfree before settling the proceeds to your bank
          account.
        </p>
      </>
    ),
  },
  {
    id: 5,
    q: 'Are there any additional charges such as setup fee?',
    a: (
      <>
        <p>
          With Cashfree, you pay ZERO Setup fees. However, we offer premium
          services at an Annual Maintenance Contract (AMC) charge*
        </p>
        <ul className="list-decimal">
          <li>
            Dedicated Account Manager with fortnightly check-ins to provide
            support and discuss the business health.
          </li>
          <li>
            Priority business support with an assured response time of 30 mins.
          </li>
          <li>20% faster resolution to dispute management.</li>
        </ul>
        <small>
          For any queries, please reach out to{' '}
          <a
            href="mailto:care@cashfree.com"
            className=" text-[12px] email-link">
            care@cashfree.com
          </a>
        </small>
      </>
    ),
  },
  {
    id: 6,
    q: (
      <div>
        What are{' '}
        <h2 className="inline font-body">UPI payment gateway charges</h2> with
        Cashfree?
      </div>
    ),
    a: (
      <>
        <p>
          UPI is available as a payment mode on Cashfree Payment Gateway. Your
          customers can use any UPI app including PhonePe, GooglePay etc to pay.
          The UPI payment gateway charges are as per the applicable law.
        </p>
        <p>
          Cashfree offers the widest range o UPI integration such as UPI
          collect, UPI intent, UPI QR Code, UPI WhatsApp link etc. You can
          choose from a range of UPI integration and achieve the desired
          checkout experience.
        </p>
      </>
    ),
  },
  {
    id: 7,
    q: 'What is a Platform Fee?',
    a: (
      <>
        <p>
          Platform fee is paid by you for services such as Hosted Checkout,
          Plugins, SDKs, Dashboard, Generation of Reports etc. The Platform Fee
          is mutually agreed upon between Cashfree and You. Platform fee is a
          fee other than the fee charged on the transaction for payments
          instruments.
        </p>
      </>
    ),
  },
];

export const autoCollectFaqData = [
  {
    id: 0,
    q: 'How does a customer pay through Auto Collect ?',
    a: (
      <>
        <p>
          Customers can open their preferred UPI app like Tez, PhonePe or even
          WhatsApp and make a payment to their unique virtual account number or
          UPI ID. This is more suited for consumers making a payment. Payments
          can also be done by logging into their bank account, adding the
          virtual account and IFSC as a beneficiary and paying. The latter is
          more suited for businesses making a payment.
        </p>
      </>
    ),
  },
  {
    id: 1,
    q:
      'How much time does it take for settlements to be credited to my account',
    a: (
      <>
        <p>Settlements are credited same day and within a few hours.</p>
      </>
    ),
  },
  {
    id: 2,
    q: 'Can I collect payments through WhatsApp or Tez ?',
    a: (
      <>
        <p>
          Yes, apps like Tez and WhatsApp allow users to send money to a UPI ID
          or any bank account. The unique account number needs to entered and
          the payment can be made.
        </p>
      </>
    ),
  },
];

export const easySplitFaqData = [
  {
    id: 0,
    q: 'What exactly is Easy Split?',
    a: (
      <>
        <p>
          Easy Split by Cashfree Payments is a split payment gateway for
          marketplaces that helps marketplaces accept payments from customers,
          deducts commissions and automatically settles payments to the vendors.
          Easy Split also takes care of customer refund processing and
          subsequent adjustment in vendor settlements.
        </p>
      </>
    ),
  },
  {
    id: 1,
    q:
      'How is vendor settlement managed when there are multiple vendors linked to one transaction?',
    a: (
      <>
        <p>
          At the time of adding a transaction, you can assign the appropriate
          numeric commission amount against each vendor that you add with
          Cashfree.
        </p>
      </>
    ),
  },
  {
    id: 2,
    q: 'Do I have to use Cashfree payment gateway to use Easy Split?',
    a: (
      <>
        <p>
          Yes, when you create an Easy Split account, Cashfree Payment Gateway
          will be automatically activated for your account. All payments
          collected via the payment gateway are automatically split using the
          integrated payment flow including refund processing.
        </p>
      </>
    ),
  },
  {
    id: 3,
    q: 'How can I define vendor commission structure to split payments?',
    a: (
      <p>
        You can specify an absolute value for the vendor&apos;s share in the
        overall transaction value. The remaining amount (Order Amount - Total
        Vendor’s share) is considered as your share.
      </p>
    ),
  },
  {
    id: 4,
    q: 'How can I process refunds and manage adjustments with vendors?',
    a: (
      <>
        <p>
          You can provide a split while initiating refunds from API or
          Dashboard. As per your inputs, the corresponding vendor’s balance is
          debited. This will help take care of any receivables from your vendor.
        </p>
      </>
    ),
  },
  {
    id: 5,
    q: 'Can I change the vendor assigned to a transaction?',
    a: (
      <>
        <p>
          If the transaction has been marked successful, you will not be able to
          change the vendor associated with the specific transaction.
        </p>
      </>
    ),
  },
  {
    id: 6,
    q: 'Can I have different settlement schedules for different vendors?',
    a: (
      <>
        <p>
          Yes, this is possible by modifying vendor’s settlement preferences. By
          default, pending vendor balances get settled automatically as per the
          settlement schedule defined by you.
        </p>
      </>
    ),
  },
  {
    id: 7,
    q: 'How much time does it take to add a vendor?',
    a: (
      <>
        <p>
          Using Easy Split, you can add any number of vendors instantly. Once a
          vendor is added, Easy Split automatically makes adjustments and settle
          amounts as per the defined commission structure and settlement cycle.
        </p>
      </>
    ),
  },
];

export const instantSettlementsFaqData = [
  {
    id: 0,
    q: 'How do I avail Instant Settlements from Cashfree Payment Gateway?',
    a: (
      <>
        <p>
          In order to enable Instant Settlements on Payment Gateway for your
          business, please reach out to us at{' '}
          <a href="mailto:care@cashfree.com" className="email-link">
            care@cashfree.com
          </a>
        </p>
      </>
    ),
  },
  {
    id: 1,
    q:
      'Will the settlement report reflect the Instant Settlements in real-time?',
    a: (
      <>
        <p>
          The settlement once made to your account will reflect instantly on
          your settlement reports.
        </p>
      </>
    ),
  },
  {
    id: 2,
    q: 'Can the settlements be processed on bank holidays?',
    a: (
      <>
        <p>
          The holiday settlements in Cashfree Payment Gateway lets you access
          your funds even on bank holidays. Charges may vary for holiday
          settlements. Contact{' '}
          <a href="mailto:care@cashfree.com" className="email-link">
            care@cashfree.com
          </a>{' '}
          to know more.
        </p>
      </>
    ),
  },
  {
    id: 3,
    q: 'Will the transactions in pending status be settled to my account?',
    a: (
      <p>
        No, only the transactions that are successful will be settled in your
        account.
      </p>
    ),
  },
  {
    id: 4,
    q:
      'What if my settlement cycle is 15 minutes and my payment is still being processed after 15 minutes? Will I get the amount settled instantly for such cases?',
    a: (
      <>
        <p>
          No, your transaction has to be successful in order for you to avail
          Instant Settlements.
        </p>
      </>
    ),
  },
  {
    id: 5,
    q:
      'As a Cashfree Payments merchant, I should be able to get instant settlements service to my bank account directly with the highest frequency of 30 mins so that I can use the funds as per my requirements directly from my bank account. Is this possible?',
    a: (
      <>
        <p>
          Yes, with our Payment Gateway Instant Settlements, you can get access
          to your funds within 30 minutes of payment capture. Please send us an
          email to{' '}
          <a href="mailto:care@cashfree.com" className="email-link">
            care@cashfree.com
          </a>{' '}
          to know more.
        </p>
      </>
    ),
  },
  {
    id: 6,
    q:
      'Can I get instant settlements on specific transactions I prefer and not on all transactions?',
    a: (
      <>
        <p>
          Yes, you can get specific transactions settled with On-Demand
          Settlements. Please reach out to us at{' '}
          <a href="mailto:care@cashfree.com" className="email-link">
            care@cashfree.com
          </a>{' '}
          to know more.
        </p>
      </>
    ),
  },
  {
    id: 7,
    q:
      'Can I download a detailed report for each instant settlement which will consist of all the transactions and the charges corresponding so that I can easily reconcile the data?',
    a: (
      <>
        <p>
          Yes, you can download the settlement report for all Instant
          Settlements from within your Payment Gateway dashboard.
        </p>
      </>
    ),
  },
];

export const payoutsFaqData = [
  {
    id: 0,
    q: 'How much time does it take to transfer money ?',
    a: (
      <>
        <p>
          Transfers to bank accounts happen instantly or within a few minutes,
          depending on your business requirement. Transfers for amounts in
          excess of ₹ 2,00,000 are restricted by banking hours and can take
          longer. A payout to UPI-BHIM ID or Paytm wallet is instant.
        </p>
      </>
    ),
  },
  {
    id: 1,
    q: 'Does it work on Sundays ?',
    a: (
      <>
        <p>
          Yes, it is possible to make bulk payments even on Sundays and bank
          holidays.
        </p>
      </>
    ),
  },
  {
    id: 2,
    q: 'How do I ensure payments are credited to the correct account ?',
    a: (
      <>
        <p>
          It is possible to use Cashfree&apos;s bank validation feature and API
          to verify whether an account exists and the name of the account holder
          at the bank. Transfers to incorrect account details fail instantly so
          you will know if a payment failed and why.
        </p>
      </>
    ),
  },
  {
    id: 3,
    q: 'How can I pay multiple people in bulk ?',
    a: (
      <>
        <p>
          This can be done by uploading a simple 4 column excel file to Cashfree
          containing the account details and amount of all the recipients. It is
          also possible to use Cashfree&apos;s API to trigger bulk payout
          requests.
        </p>
      </>
    ),
  },
];

export const verificationSuiteFaqData = [
  {
    id: 0,
    q: 'What is the pricing of Verification features?',
    a: (
      <>
        <p>
          You can{' '}
          <a
            // href="https://merchant.cashfree.com/merchants/signup?source-action=Verification%20Suite&action=Sign%20Up&button-id=StartNow_Hero"
            href="https://merchant.cashfree.com/merchants/signup?source-action=Pricing%20Page&action=Sign%20Up&button-id=StartNow_VSFAQ1"
            className="email-link">
            create an account
          </a>{' '}
          and check pricing of each Verification feature or{' '}
          <a
            // href="https://www.cashfree.com/contact-sales/?source-action=Verification%20Suite&action=Contact%20Sales&button-id=ContactSales_Hero"
            href="https://www.cashfree.com/contact-sales?source-action=Pricing%20Page&action=Sign%20Up&button-id=ContactSales_VSFAQ1"
            className="email-link">
            fill this form to request pricing
          </a>
          . When you create an account, you get free credits worth ₹100/- . You
          can use the credits to do free verifications using dashboard or API.
        </p>
      </>
    ),
  },
  {
    id: 1,
    q: 'Do I have to sign up for all Verification products individually?',
    a: (
      <>
        <p>
          No, you can create an account for Verification Suite, and all the
          Verification tools such as, Bank Account Verification, PAN
          Verification, Aadhaar Verification, and GSTIN Verification will be
          accessible to you.
        </p>
      </>
    ),
  },
];
